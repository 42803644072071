import React from "react";
import { useNavigate } from "react-router-dom";
import { FaTwitter, FaGithub } from 'react-icons/fa';

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center text-sm border-t border-cyan-400 px-10 py-4 mt-40 mx-10">
      <div
        onClick={() => navigate("/term")}
        className="text-neutral-500 hover:underline hover:cursor-pointer"
      >
        Term and Conditions | © ChainLocker LLC
      </div>
      <div className="flex justify-center mt-2">
        <a href="https://twitter.com/ChainLockerLLC" target="_blank" rel="noopener noreferrer" className="mx-2 text-neutral-500">
          <FaTwitter size={20} />
        </a>
        <a href="https://github.com/ChainLockerLLC" target="_blank" rel="noopener noreferrer" className="mx-2 text-neutral-500">
          <FaGithub size={20} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
