import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useMainContext } from "../../context/contextProvider";
import { updateBuyer } from "../../utils/useContracts";
import { EthLockerABI, TokenLockerABI } from "../../contracts/abis";
import { useContractRead } from "wagmi";
import { useEthersSigner } from "../../utils/ethersAdapter";

export default function UpdateBuyerModal() {
  const signer = useEthersSigner();
  const {
    modalStatus,
    setModalStatus,
    selectedChainLocker,
    formData,
    setFormData,
  } = useMainContext();

  const { data: buyer } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "buyer",
  });

  function closeModal() {
    setModalStatus({ ...modalStatus, updateBuyerModal: false });
  }

  return (
    <Transition appear show={modalStatus.updateBuyerModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Update Buyer
                </Dialog.Title>
                <p className="text-xs text-black mt-4">
                  Current Buyer: <span className="text-black/90">{buyer}</span>
                </p>
                <div className="col-span-4 md:col-span-2 mb-4 mt-2">
                  <input
                    type="text"
                    name="_maximumValue"
                    onChange={(e) =>
                      setFormData({ ...formData, buyerAddress: e.target.value })
                    }
                    placeholder="New Buyer Address"
                    className="w-full text-black border border-black bg-transparent rounded-md outline-none px-2 py-1.5"
                  />
                </div>
                <button
                  onClick={() =>
                    updateBuyer(
                      selectedChainLocker?.chainLockerAddress,
                      selectedChainLocker?.tokenContract ===
                        "0x0000000000000000000000000000000000000000"
                        ? EthLockerABI
                        : TokenLockerABI,
                      signer,
                      formData.buyerAddress
                    )
                  }
                  className="h-[38px] w-full mt-auto bg-primary text-white px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
                >
                  Update Buyer
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
