import React from "react";
import toast from "react-hot-toast";

function Documentation() {
  return (
    <div className="max-w-4xl mx-auto px-6">
      <h5 className="text-2xl text-primary font-light mt-20">Technical
        <strong className="text-white font-semibold"> Documentation</strong>
      </h5>
      <p className="mt-3 text-start"><br />The ChainLocker smart contracts are open source. <br /><br />Review the readme, code, and audit reports <span className="cursor-pointer hover:text-cyan-400 text-primary" onClick={() => window.open("https://github.com/ChainLockerLLC/smart-contracts", "_blank")}>here</span>.<br /><br />
        To request the addition of a Receipt data feed address, fund additional network deployments or data feeds, or request other services, please email <span className="cursor-pointer hover:text-cyan-400 text-primary" onClick={() => {
          navigator.clipboard.writeText("contact@chainlocker.xyz");
          toast.success("Email Address Copied!");
        }} >contact@chainlocker.xyz</span>.<br />
      </p>
    </div>
  );
}

export default Documentation;
