import React from "react";
import { ethers } from "ethers";
import { EthLockerABI, TokenLockerABI, TokenABI } from "../contracts/abis";
import { useBalance, useContractRead } from "wagmi";
import { GoCopy } from "react-icons/go";
import toast from "react-hot-toast";
//import { useEventContext } from "../contracts/eventsProvider";

function DirectoryDetails({ selectedChainLocker }) {
  console.log("Selected ChainLocker:", selectedChainLocker);
  // const {
  //getEthLocker_Executed,
  //getTokenLocker_Executed,
  //lockerExecutedStatus
  //} = useEventContext();
  //const [isExecuted, setIsExecuted] = useState(false);
  //const [hasFetchedStatus, setHasFetchedStatus] = useState(false);

  const { data: buyer } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "buyer",
  });
  const { data: buyerApproved } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "buyerApproved",
  });
  const { data: seller } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "seller",
  });
  const { data: deposited } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "deposited",
  });
  const { data: sellerApproved } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "sellerApproved",
  });
  const { data: minimumValue } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "minimumValue",
  });
  const { data: maximumValue } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "maximumValue",
  });
  const { data: valueCondition } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "valueCondition",
  });
  const { data: dataFeedProxy } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "dataFeedProxy",
  });
  const { data: refundable } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "refundable",
  });
  const { data: openOffer } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "openOffer",
  });
  const { data: deposit } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "deposit",
  });
  const { data: decimals } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "decimals",
  });
  const { data: symbol } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "symbol",
  });
  const { data: pendingWithdraw } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "pendingWithdraw",
  });
  const { data: tokensInEscrow } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "balanceOf",
    args: [selectedChainLocker?.chainLockerAddress],
  });
  const { data: balanceData } = useBalance({
    address: selectedChainLocker?.chainLockerAddress,
    formatUnits: 'wei',
  });

  const { data: totalAmount } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "totalAmount",
  });
  const { data: expirationTime } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi:
      selectedChainLocker?.tokenContract ===
        "0x0000000000000000000000000000000000000000"
        ? EthLockerABI
        : TokenLockerABI,
    functionName: "expirationTime",
  });

  const getExpirationDate = (timestamp) => {
    const currentTime = new Date();
    const date = new Date(parseInt(timestamp) * 1000);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
    const expiredDate = formattedDate.concat("   *** EXPIRED ***");
    if (currentTime > date) {
      return expiredDate;
    }
    else return formattedDate;
  };

  const formatTokenAmount = (amount, decimals, symbol) => {
    let valueNumber;
    try {
      if (amount === undefined || amount === null || amount === '' || amount === "0x") {
        valueNumber = ethers.BigNumber.from(0);
      } else {
        valueNumber = ethers.BigNumber.from(amount);
      }
    } catch (error) {
      console.error("Error converting to BigNumber:", error);
      return "BigNumber Error";
    }
    let symbolString;
    if (selectedChainLocker?.tokenContract === undefined || selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000") {
      symbolString = "ETH";
    } else {
      symbolString = symbol;
    }
    let formattedString;
    if (selectedChainLocker?.tokenContract === undefined || selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000") {
      formattedString = ethers.utils.formatUnits(valueNumber, "18").toString();
    } else {
      formattedString = ethers.utils.formatUnits(valueNumber, decimals).toString();
    }
    const [wholePart, decimalPart] = formattedString.split(".");
    return `${wholePart}.${decimalPart} ${symbolString}`;
  };

  const formatCopy = (amount, decimals) => {
    let valueNumber;
    try {
      if (amount === undefined || amount === null || amount === '' || amount === "0x") {
        valueNumber = ethers.BigNumber.from(0);
      } else {
        valueNumber = ethers.BigNumber.from(amount);
      }
    } catch (error) {
      console.error("Error converting to BigNumber:", error);
      return "BigNumber Error";
    }
    let formattedString;
    if (selectedChainLocker?.tokenContract === undefined || selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000") {
      formattedString = ethers.utils.formatUnits(valueNumber, "18").toString();
    } else {
      formattedString = ethers.utils.formatUnits(valueNumber, decimals).toString();
    }
    const [wholePart, decimalPart] = formattedString.split(".");
    return `${wholePart}.${decimalPart}`;
  };

  const escrowBalance = () => {
    console.log("balance", balanceData);
    try {
      let pendingWithdrawBN = ethers.BigNumber.from(pendingWithdraw || 0);
      let balanceBN = ethers.BigNumber.from(0);

      if (selectedChainLocker?.tokenContract === undefined || selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000") {
        if (balanceData && balanceData.value !== undefined) {
          balanceBN = ethers.BigNumber.from(balanceData.value).sub(pendingWithdrawBN);
        }
      } else if (tokensInEscrow !== "0" && tokensInEscrow !== undefined) {
        balanceBN = ethers.BigNumber.from(tokensInEscrow).sub(pendingWithdrawBN);
      }

      return balanceBN;
    } catch (error) {
      console.error("Error calculating escrow balance:", error);
      return ethers.BigNumber.from("0");
    }
  }

  const balanceInEscrow = escrowBalance();
  const isBalanceInEscrow = totalAmount !== undefined && balanceInEscrow.eq(ethers.BigNumber.from(totalAmount || 0));

  const displayDeposit = formatTokenAmount(deposit, decimals, symbol);
  const displayDepositCopy = formatCopy(deposit, decimals);
  const displayTotal = formatTokenAmount(totalAmount, decimals, symbol);
  const displayTotalCopy = formatCopy(totalAmount, decimals);
  const displayPendingWithdraw = formatTokenAmount(pendingWithdraw, decimals, symbol);
  const displayAmountInEscrow = formatTokenAmount(balanceInEscrow, decimals, symbol);
  /*const checkIsExecuted = async () => {
    let called = false;
    let currentLocker;
    if (!called && selectedChainLocker?.chainLockerAddress !== currentLocker) {
      called = true;
      let executed = false;
      const isEthLocker = selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000" || selectedChainLocker?.tokenContract === undefined;
      if (isEthLocker) {
        executed = await getEthLocker_Executed(selectedChainLocker?.chainLockerAddress);
      } else {
        executed = await getTokenLocker_Executed(selectedChainLocker?.chainLockerAddress);
      }
      currentLocker = selectedChainLocker?.chainLockerAddress;
      console.log("Executed Status:", executed);
      return executed;  // Update the executed status here
    } else {
      return false;
    }
  };
  const isExecuted = checkIsExecuted();

  useEffect(() => {
    console.log("useEffect triggered", selectedChainLocker, hasFetchedStatus);
    const isEthLocker = selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000" || selectedChainLocker?.tokenContract === undefined;
    setHasFetchedStatus(false);
    const fetchExecutedStatus = async () => {
      try {
        let executed;
        if (isEthLocker) {
          executed = await getEthLocker_Executed(selectedChainLocker?.chainLockerAddress);
        } else {
          executed = await getTokenLocker_Executed(selectedChainLocker?.chainLockerAddress);
        }
        console.log("Executed Status:", executed);
        setIsExecuted(executed);  // Update the executed status here
      } catch (error) {
        console.error("Error fetching executed status:", error);
      } finally {
        setHasFetchedStatus(true); // Set fetched status after the operation
      }
    }; 

    if (!hasFetchedStatus || !(lockerAddress in lockerExecutedStatus) || lockerExecutedStatus[lockerAddress] !== isExecuted) {
      fetchExecutedStatus();
    }
  }, [selectedChainLocker, getEthLocker_Executed, getTokenLocker_Executed, hasFetchedStatus]);

  useEffect(() => {
    console.log("Second useEffect triggered", selectedChainLocker, lockerExecutedStatus);
    if (selectedChainLocker?.chainLockerAddress in lockerExecutedStatus) {
      setIsExecuted(lockerExecutedStatus[selectedChainLocker?.chainLockerAddress]);
    }
    setHasFetchedStatus(true); // Set fetched status after the operation

  }, [lockerExecutedStatus, selectedChainLocker]);*/

  return (
    <div className="my-8 ">
      <p className="mb-2">Details</p>
      <div className="border-[#313131] border-y grid grid-cols-1 lg:grid-cols-2 p-4">
        <div className="flex items-center">
          <strong className="mr-1 text-neutral-400">Buyer:</strong>
          <span className="hidden sm:block">{buyer}</span>{" "}
          <span className="sm:hidden block">
            {buyer?.slice(0, 10).concat("..." + buyer.slice(-10))}
          </span>
          <GoCopy
            onClick={() => {
              navigator.clipboard.writeText(buyer);
              toast.success("Buyer Address Copied!");
            }}
            className="ml-1 cursor-pointer"
          />
        </div>
        <div className="flex items-center">
          <strong className="mr-1 text-neutral-400">Seller:</strong>
          <span className="hidden sm:block">{seller}</span>{" "}
          <span className="sm:hidden block">
            {seller?.slice(0, 10).concat("..." + seller.slice(-10))}
          </span>
          <GoCopy
            onClick={() => {
              navigator.clipboard.writeText(seller);
              toast.success("Seller Address Copied!");
            }}
            className="ml-1 cursor-pointer"
          />
        </div>
        <div>
          <strong className="text-neutral-400">Buyer Ready to Execute:</strong>{" "}
          <span
            className={`${buyerApproved ? "text-teal-300" : "text-primary"}`}
          >
            {buyerApproved ? "Yes" : "No"}
          </span>
        </div>
        <div>
          <strong className="text-neutral-400">Seller Ready to Execute:</strong>{" "}
          <span
            className={`${sellerApproved ? "text-teal-300" : "text-primary"}`}
          >
            {sellerApproved ? "Yes" : "No"}
          </span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400">Value Condition:</strong>{" "}
          <span>
            {valueCondition === 0 ? "None" : ""}{valueCondition === 1 ? "<= Maximum Value" : ""}{valueCondition === 2 ? ">= Minimum Value" : ""}{valueCondition === 3 ? "Both >= Minimum Value and >= Minimum Value" : ""}
          </span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400">Data Feed:</strong>{" "}
          <span>
            {valueCondition === 0 ? "0x0" : dataFeedProxy}
          </span>
        </div>
        <div>
          <strong className="text-neutral-400">Minimum Value:</strong>{" "}
          <span>{minimumValue?.toString()}</span>
        </div>
        <div>
          <strong className="text-neutral-400">Maximum Value:</strong>{" "}
          <span>{maximumValue?.toString()}</span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400">Deposit Refundable at Expiry:</strong>{" "}
          <span
            className={`${refundable ? "text-teal-300" : "text-primary"}`}
          >
            {refundable ? "Yes" : "No"}
          </span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400">Open Offer:</strong>{" "}
          <span
            className={`${openOffer ? "text-teal-300" : "text-primary"}`}
          >
            {openOffer ? "Yes" : "No"}
          </span>
        </div>
        <div className="flex items-center">
          <strong className="mr-1 text-neutral-400">Deposit Amount:</strong>
          <span>{displayDeposit}</span>{" "}
          <GoCopy
            onClick={() => {
              navigator.clipboard.writeText(displayDepositCopy);
              toast.success("Deposit Amount Copied!");
            }}
            className="ml-1 cursor-pointer"
          />
        </div>
        <div className="flex items-center">
          <strong className="mr-1 text-neutral-400">Total Amount:</strong>
          <span>{displayTotal}</span>{" "}
          <GoCopy
            onClick={() => {
              navigator.clipboard.writeText(displayTotalCopy);
              toast.success("Total Amount Copied!");
            }}
            className="ml-1 cursor-pointer"
          />
        </div>
        <div>
          <strong className="text-neutral-400">Currently Deposited:</strong>{" "}
          <span>
            {displayAmountInEscrow}
          </span>
        </div>
        <div>
          <strong className="mr-1 text-neutral-400">Pending Withdraw:</strong>
          <span>{displayPendingWithdraw}</span>{" "}
        </div>
        {/* <hr className="col-span-2 border-[#313131] mx-6 my-2" /> */}
        <div>
          <strong className="text-neutral-400">Deposit in Escrow:</strong>{" "}
          <span
            className={`${deposited ? "text-teal-300" : "text-primary"
              }`}
          >
            {deposited ? "Yes" : "No"}
          </span>
        </div>
        <div>
          <strong className="text-neutral-400">Total Amount in Escrow:</strong>{" "}
          <span
            className={`${isBalanceInEscrow ? "text-teal-300" : "text-primary"
              }`}
          >
            {isBalanceInEscrow ? "Yes" : "No"}
          </span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400">Expires:</strong>{" "}
          <span>{getExpirationDate(expirationTime?.toString())}</span>
        </div>
        <div className="mt-2">
          <strong className="text-neutral-400"> </strong>{" "}
          <span
          // className={`${isExecuted ? "text-teal-300" : "text-primary"}`} {isExecuted ? "Yes" : "No"}
          >

          </span>
        </div>
      </div>
    </div>
  );
}

export default DirectoryDetails;
