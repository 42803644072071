import { createContext, useContext, useEffect, useState } from "react";
import { ChainLockerFactoryABI } from "./abis";
import { ChainLockerFactory } from "./addresses";
import { useContractEvent, useNetwork, useWalletClient } from "wagmi";
import { ethers } from 'ethers';

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const { chain } = useNetwork();
  const { data: walletClient, isError } = useWalletClient();
  const [
    ChainLockerFactory_DeploymentEvent,
    setChainLockerFactory_DeploymentEvent
  ] = useState([]);
  //const [lockerExecutedStatus, setLockerExecutedStatus] = useState({});
  const [chainId, setChainId] = useState(null);
  const factoryInterface = new ethers.utils.Interface(ChainLockerFactoryABI);

  //Powered by PolygonScan APIs
  const polyFactoryApiUrl = `https://api.polygonscan.com/api?module=logs&action=getLogs&fromBlock=48056141&address=0x5005f92fF75c94f95673793D652c013cF645b133&apikey=FM5I3FKRHQHRHZT1Y5Y5ENAQEU4UWJV2N1`;
  //Powered by PolygonScan APIs
  const polyZkFactoryApiUrl = `https://api-zkevm.polygonscan.com/api?module=logs&action=getLogs&fromBlock=6603330&address=0x9E444E9F6e248e1a43E1a76C51B685060BeAd3C1&apikey=IJCU7FR1RHPP4C5ICI6Z1Q1GH8QQ5HUR4D`;
  //Powered by Optimism Etherscan APIs
  const opFactoryApiUrl = `https://api-optimistic.etherscan.io/api?module=logs&action=getLogs&fromBlock=109206020&address=0x0BEeD41753dF1194e32fD50E7dBc662FA2b2BD43&apikey=BWVEZEBIK8AN49IINT888YVIVJQY2K9YIH`;
  //Powered by Arbiscan.io APIs
  const arbFactoryApiUrl = `https://api.arbiscan.io/api?module=logs&action=getLogs&fromBlock=130199156&address=0x9E444E9F6e248e1a43E1a76C51B685060BeAd3C1&apikey=EJSPHB653D8U2JDDDK4X5ETFHF2ANKK6CG`;
  /*
    //Powered by Etherscan APIs
    const etherscanLockerApiUrl = 'https://api.etherscan.io/api?module=logs&action=getLogs&fromBlock=18071233&address=';
    const etherscanLockerApiKey = '&apikey=KR6YV6QV8T72TGD79WPHVD11T4H59GY7CG';
    const goerliscanLockerApiUrl = 'https://api-goerli.etherscan.io/api?module=logs&action=getLogs&fromBlock=9741618&address='
    //Powered by PolygonScan APIs
    const polyLockerApiUrl = `https://api.polygonscan.com/api?module=logs&action=getLogs&fromBlock=48056141&address=`;
    const polyLockerApiKey = '&apikey=1SKPSUEB5TUVXWDXX6UB9298M8EDGEFEE3';
    //Powered by PolygonScan APIs
    const polyZkLockerApiUrl = `https://api-zkevm.polygonscan.com/api?module=logs&action=getLogs&fromBlock=6603330&address=`;
    const polyZkLockerApiKey = '&apikey=C2PN3PRZWC62582SD34RS5H3X23RCXYFV7';
    //Powered by Optimism Etherscan APIs
    const opLockerApiUrl = `https://api-optimistic.etherscan.io/api?module=logs&action=getLogs&fromBlock=109206020&address=`;
    const opLockerApiKey = '&apikey=QIIAB2SQGWSCEKFFCHY63WC2AMNVWAVKJ2';
    //Powered by Arbiscan.io APIs
    const arbLockerApiUrl = `https://api.arbiscan.io/api?module=logs&action=getLogs&fromBlock=130199156&address=`;
    const arbLockerApiKey = '&apikey=Y296N22DR953YM5JIRCXE91YBUYUW6YZ37';
  
    const updateLockerExecutedStatus = (LockerAddress, executed) => {
      setLockerExecutedStatus(prevState => ({
        ...prevState,
        [LockerAddress]: executed,
      }));
    };*/

  useEffect(() => {
    if (chain) {
      setChainId(chain.id);
    }
  }, [chain]);

  useEffect(() => {
    if (isError) return;
    if (walletClient) {
      const ChainLockerFactoryAddress = ChainLockerFactory[chainId];
      console.log("Current ChainLocker Address:", ChainLockerFactoryAddress);

      if (!ChainLockerFactoryAddress) {
        console.error("No contract address found for the current network.");
        return;
      }
      const provider = new ethers.providers.Web3Provider(walletClient.transport, chainId);
      const contract = new ethers.Contract(ChainLockerFactoryAddress, ChainLockerFactoryABI, provider);

      const fetchEtherscanFactoryEvents = async (apiUrl) => {
        fetch(apiUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            if (data.status && data.status === '1' && data.result) {
              const normalizedEvents = data.result.map(event =>
                mapEtherscanEventToEthersEvent(event, factoryInterface));
              console.log("Fetched Events:", normalizedEvents);
              setChainLockerFactory_DeploymentEvent(normalizedEvents);
            } else {
              console.error("Error fetching events: No results found.");
            }
          })
          .catch(error => {
            console.error("Error fetching events:", error);
          });
      }

      if (chainId !== 137 && chainId !== 1101 && chainId !== 10 && chainId !== 42161)
        contract.queryFilter(contract.filters.ChainLockerFactory_Deployment()).then(events => {
          console.log("Fetched Events:", events);
          setChainLockerFactory_DeploymentEvent(events);
        }).catch(error => {
          console.error("Error fetching events:", error);
        });
      else if (chainId === 137) fetchEtherscanFactoryEvents(polyFactoryApiUrl);
      else if (chainId === 1101) fetchEtherscanFactoryEvents(polyZkFactoryApiUrl);
      else if (chainId === 10) fetchEtherscanFactoryEvents(opFactoryApiUrl);
      else if (chainId === 42161) fetchEtherscanFactoryEvents(arbFactoryApiUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletClient, chainId, isError]);

  function mapEtherscanEventToEthersEvent(etherscanEvent, factoryInterface) {
    const decodedData = factoryInterface.decodeEventLog(
      "ChainLockerFactory_Deployment",
      etherscanEvent.data,
      etherscanEvent.topics
    );
    return {
      args: decodedData,
    };
  }
  /*
    const getEthLocker_Executed = async (EthLockerAddress) => {
      const fetchEvents = async (apiUrl) => {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status && data.status === '1' && data.result && data.result.length > 0) {
          console.log("EthLocker_Executed Event Found for:", EthLockerAddress);
          //updateLockerExecutedStatus(EthLockerAddress, true);
          return true;
        } else {
          console.log("No EthLocker_Executed Event Found for:", EthLockerAddress);
          //updateLockerExecutedStatus(EthLockerAddress, false);
          return false;
        }
      };
  
      let executed = false;
      if (chainId === 137) executed = await fetchEvents(polyLockerApiUrl.concat(EthLockerAddress).concat(polyLockerApiKey));
      else if (chainId === 1101) executed = await fetchEvents(polyZkLockerApiUrl.concat(EthLockerAddress).concat(polyZkLockerApiKey));
      else if (chainId === 10) executed = await fetchEvents(opLockerApiUrl.concat(EthLockerAddress).concat(opLockerApiKey));
      else if (chainId === 42161) executed = await fetchEvents(arbLockerApiUrl.concat(EthLockerAddress).concat(arbLockerApiKey));
      else if (chainId === 5) executed = await fetchEvents(goerliscanLockerApiUrl.concat(EthLockerAddress).concat(etherscanLockerApiKey));
      else executed = await fetchEvents(etherscanLockerApiUrl.concat(EthLockerAddress).concat(etherscanLockerApiKey));
      //updateLockerExecutedStatus(EthLockerAddress, executed);
      return executed;
  
    };
  
    const getTokenLocker_Executed = async (TokenLockerAddress) => {
      try {
        const fetchEvents = async (apiUrl) => {
          const response = await fetch(apiUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (data.status && data.status === '1' && data.result && data.result.length > 0) {
            console.log("TokenLocker_Executed Event Found for:", TokenLockerAddress);
            //updateLockerExecutedStatus(TokenLockerAddress, true);
            return true;
          } else {
            console.log("No TokenLocker_Executed Event Found for:", TokenLockerAddress);
            //updateLockerExecutedStatus(TokenLockerAddress, false);
            return false;
          }
        };
  
        let executed = false;
        if (chainId === 137) executed = await fetchEvents(polyLockerApiUrl.concat(TokenLockerAddress).concat(polyLockerApiKey));
        else if (chainId === 1101) executed = await fetchEvents(polyZkLockerApiUrl.concat(TokenLockerAddress).concat(polyZkLockerApiKey));
        else if (chainId === 10) executed = await fetchEvents(opLockerApiUrl.concat(TokenLockerAddress).concat(opLockerApiKey));
        else if (chainId === 42161) executed = await fetchEvents(arbLockerApiUrl.concat(TokenLockerAddress).concat(arbLockerApiKey));
        else if (chainId === 5) executed = await fetchEvents(goerliscanLockerApiUrl.concat(TokenLockerAddress).concat(etherscanLockerApiKey));
        else executed = await fetchEvents(etherscanLockerApiUrl.concat(TokenLockerAddress).concat(etherscanLockerApiKey));
        // updateLockerExecutedStatus(TokenLockerAddress, executed);
        return executed;
      } catch (error) {
        console.error("Error in getTokenLocker_Executed:", error);
        return false;
      }
    };*/

  const DeploymentEvents = useContractEvent(
    {
      address: ChainLockerFactory[chainId],
      abi: ChainLockerFactoryABI,
      eventName: "ChainLockerFactory_Deployment",
      listener(log) {
        console.log("Fetched Events:", log)
      },
    });

  useEffect(() => {
    if (DeploymentEvents) {
      console.log("ChainLockerFactory_Deployment", DeploymentEvents);
      setChainLockerFactory_DeploymentEvent(DeploymentEvents);
    }
  }, [DeploymentEvents]);

  return (
    <EventContext.Provider
      value={{
        ChainLockerFactory_DeploymentEvent,
        //getEthLocker_Executed,
        //getTokenLocker_Executed,
        //lockerExecutedStatus
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => {
  return useContext(EventContext);
};
