import React, { useState } from "react";
import Logo from "../assets/LogoLG.png";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="w-full relative flex justify-between items-center px-10 py-6">
      <div className="flex items-center z-50">
        <img
          src={Logo}
          alt="Logo"
          className="w-20 h-20 rounded-full mr-3 ml-6"
        />
        <h5 className="text-xl">
          <strong>Chain</strong>Locker
        </h5>
      </div>

      {/* Desktop Menu */}
      <ul className="hidden lg:flex items-center">
        <li
          onClick={() => navigate("/")}
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Home
        </li>
        <li
          onClick={() => navigate("/create")}
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Create
        </li>
        <li
          onClick={() => navigate("/directory")}
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Directory
        </li>
        <li
          onClick={() => navigate("/documentation")}
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Documentation
        </li>
        <li
          // onClick={() =>
          //   window.open(
          //     `mailto:ChainLocker_LLC@protonmail.com?subject=${encodeURIComponent(
          //       "Consult Requested"
          //     )}`
          //   )
          // }
          onClick={() => {
            navigate("/consult");
          }}
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Request Consult
        </li>
        <li
          onClick={() =>
            window.open("https://chainlocker.medium.com", "_blank")
          }
          className="inline mr-6 cursor-pointer hover:text-cyan-400"
        >
          Blog
        </li>
        {/* <li className="inline mr-6">
         <WalletConnectButton />
        </li> */}
      </ul>

      {/* Mobile Menu Button */}
      <button
        className="lg:hidden"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="absolute top-20 right-0 bg-black/90 h-[90vh] w-full z-40 px-10 pt-10">
          <ul className="text-center font-medium text-xl">
            <li
              onClick={() => {
                navigate("/");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Home
            </li>
            <li
              onClick={() => {
                navigate("/create");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Create
            </li>
            <li
              onClick={() => {
                navigate("/directory");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Directory
            </li>
            <li
              onClick={() => {
                navigate("/documentation");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Docs
            </li>
            <li
              onClick={() => {
                navigate("/consult");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Consult
            </li>
            <li
              onClick={() => {
                window.open("https://chainlocker.medium.com", "_blank");
                setMenuOpen(false);
              }}
              className="cursor-pointer hover:text-cyan-400 mb-4"
              style={{ display: "block" }}
            >
              Blog
            </li>
            {/* <li className="inline mr-6">
         <WalletConnectButton />
        </li> */}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
