import { ethers } from "ethers";
import { EthLockerABI, TokenABI, TokenLockerABI } from "../contracts/abis";
import toast from "react-hot-toast";
import { dateToTimestamp } from "../utils/timeFormator";

export const symbol = async (address, signer) => {
  const contract = new ethers.Contract(address, TokenABI, signer);
  return await contract.symbol();
};

export const deployChainLocker = async (
  factory,
  data,
  minimumFee,
  modalStatus,
  setModalStatus,
  formData,
  setFormData,
  signer
) => {
  const currentDate = new Date();
  if (data._expirationTime < dateToTimestamp(currentDate)) {
    toast.error("Error: Expiration Time in the past");
    return;
  }
  if (Number(data._minimumValue) > Number(data._maximumValue)) {
    toast.error("Error: Minimum Value greater than Maximum Value");
    return;
  }
  if (data._totalAmount === "0") {
    toast.error("Error: Total Amount is Zero");
    return;
  }
  if (Number(data._deposit) > Number(data._totalAmount)) {
    toast.error("Error: Deposit greater than Total Amount");
    return;
  }
  const factoryContract = new ethers.Contract(factory.address, factory.abi, signer);
  toast.promise(
    factoryContract.deployChainLocker(
      data._refundable,
      data._openOffer,
      data._valueCondition,
      data._maximumValue,
      data._minimumValue,
      data._deposit,
      data._totalAmount,
      data._expirationTime,
      data._seller,
      data._buyer,
      data._tokenContract || "0x0000000000000000000000000000000000000000",
      data._dataFeedProxyAddress,
      { value: minimumFee }
    )
      .then((transaction) => {
        toast.promise(
          transaction.wait().then((tx) => {
            console.log("Enter Status:", tx);
            setFormData({
              ...formData,
              transaction: tx,
            });
            setModalStatus({ ...modalStatus, transactionModal: true });
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Error! Please check your inputs and your wallet";
        if (error.code === ethers.errors.CALL_EXCEPTION) {
          errorMessage = error.error?.message || error.reason || errorMessage;
        } else if (error.data && error.data.message) {
          errorMessage = error.data.message;
        } else {
          toast.error(errorMessage);
        }
      }),
    {
      loading: "Please wait, connecting wallet...",
      success: "Transaction Approved!",
      error: "Connection error",
    }
  );
};

// Read Contract ====================================================== Eth/TokenLocker

// Write Contract ===================================================== Eth/TokenLocker

export const updateBuyer = async (address, abi, signer, updatedAddress) => {
  const contract = new ethers.Contract(address, abi, signer);
  console.log("Buyer Contract:", contract, "Address:", updatedAddress);
  toast.promise(
    contract
      .updateBuyer(updatedAddress)
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Enter Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
            error: "Something went wrong!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Error! Please check the provided address and your wallet";
        if (error.code === ethers.errors.CALL_EXCEPTION) {
          errorMessage = error.error?.message || error.reason || errorMessage;
        } else if (error.data && error.data.message) {
          errorMessage = error.data.message;
        } else {
          toast.error(errorMessage);
        }
      }),
    {
      loading: "Please wait, connecting wallet...",
      error: "Connection error",
    }
  );
};

export const updateSeller = async (address, abi, signer, updatedAddress) => {
  const contract = new ethers.Contract(address, abi, signer);
  toast.promise(
    contract
      .updateSeller(updatedAddress)
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Enter Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
            error: "Something went wrong!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Error! Please check the provided address and your wallet";
        if (error.code === ethers.errors.CALL_EXCEPTION) {
          errorMessage = error.error?.message || error.reason || errorMessage;
        } else if (error.data && error.data.message) {
          errorMessage = error.data.message;
        } else {
          toast.error(errorMessage);
        }
      }),
    {
      loading: "Please wait, connecting wallet...",
      error: "Connection Error",
    }
  );
};

async function requestSignatureForPermit(nonces, signer, owner, tokenContract, spender, value, deadline) {
  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]
  };
  // Retrieve the current nonce for the owner from the token contract
  const message = {
    owner,
    spender,
    value,
    nonce: nonces, // Nonce as a decimal string
    deadline
  };
  const contract = new ethers.Contract(tokenContract, TokenABI, signer);
  const name = await contract.name();
  let version;
  try {
    version = await tokenContract.version();
  } catch {
    version = "1"; // Default to "1" if the contract doesn't have 'version'
  }
  const chainId = (await signer.provider.getNetwork()).chainId;
  const domain = {
    name: name,
    version: version,
    chainId: chainId,
    verifyingContract: tokenContract
  };

  const signature = await signer._signTypedData(domain, types, message);
  // Split the signature into its components
  const { r, s, v } = ethers.utils.splitSignature(signature);
  // Return the formatted permit data
  return { owner, spender, value, deadline, v, r, s };
}

export const handleDepositClick = async (tokenContract,
  chainLockerAddress,
  signer,
  depositAmount,
  allowance,
  expirationTime,
  domainSeparator,
  permitTypehash,
  nonces) => {
  if (depositAmount <= 0) {
    toast.error("Error: amount must be greater than zero");
    return;
  }
  if (
    tokenContract ===
    "0x0000000000000000000000000000000000000000" || tokenContract ===
    undefined
  ) {
    const contract = new ethers.Contract(chainLockerAddress, EthLockerABI, signer);
    const openOffer = await contract.openOffer();
    const totalAmount = await contract.totalAmount();
    const pendingWithdraw = await contract.pendingWithdraw();
    if (openOffer && ((depositAmount - pendingWithdraw) < totalAmount)) {
      toast.error("Error: to accept an Open Offer, you must deposit the Total Amount");
      return;
    }
    if ((depositAmount - pendingWithdraw) > totalAmount) {
      toast.error("Error: amount greater than the Total Amount");
      return;
    }
    toast.promise(
      signer
        .sendTransaction({
          to: chainLockerAddress,
          value: depositAmount,
        })
        .then((transaction) => {
          toast.promise(
            transaction.wait((tx) => {
              console.log("Enter Status:", tx);
            }),
            {
              loading: "Please wait, your deposit is processing...",
              success: "Successful!",
              error: "Transaction Failed",
            }
          );
        })
        .catch((error) => {
          console.log("Enter Error: ", error);
          let errorMessage = "Transaction Failed: " + (error.message || "Unknown Error");
          toast.error(errorMessage);
        }),
      {
        loading: "Please wait, connecting wallet...",
        success: "Successful!",
        error: "Transaction Failed",
      }
    );
  }
  else {
    let supportsPermit;
    try {
      // check domainSeparator, permitTypehash, and ensure not Polygon POS USDC nor Arbitrum USDC (uses non-EIP712)
      if (domainSeparator !== undefined && permitTypehash === '0x6e71edae12b1b97f4d1f60370fef10105fa2faae0126114a169c64845d6126c9' && (tokenContract !== '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174' && tokenContract !== '0xaf88d065e77c8cC2239327C5EDb3A432268e5831')) supportsPermit = true;
    } catch (error) {
      console.error("Error checking for DOMAIN_SEPARATOR: ", error);
      supportsPermit = false;
    }

    if (supportsPermit) {
      const signerAddress = await signer.getAddress();
      console.log("address:", signerAddress);
      const permitData = await requestSignatureForPermit(nonces, signer, signerAddress, tokenContract, chainLockerAddress, depositAmount, expirationTime);
      await depositAmountInTokenLockerWithPermit(
        signerAddress,
        chainLockerAddress,
        signer,
        permitData
      );
    } else {
      await depositAmountInTokenLocker(
        //address,
        tokenContract,
        chainLockerAddress,
        signer,
        depositAmount,
        allowance
      );
    }
  }
}

export const handleDepositAmountInTokenLocker = async (lockerAddress, signer, amount) => {
  try {
    const contract = new ethers.Contract(lockerAddress, TokenLockerABI, signer);
    console.log("Contract instance created", contract);
    const transaction = await contract.depositTokens(amount);
    console.log("Transaction sent", transaction);
    const receipt = await transaction.wait();
    console.log("Transaction confirmed", receipt);
    toast.success("Deposit successful!");
  } catch (error) {
    console.error("Error during deposit: ", error);
    toast.error("A deposit error occurred --- please check the expiry, amount, and your address.");
  }
};

export const depositAmountInTokenLocker = async (
  //userAddress,
  tokenAddress,
  lockerAddress,
  signer,
  amount,
  allowance
) => {
  const contract = new ethers.Contract(lockerAddress, TokenLockerABI, signer);
  const openOffer = await contract.openOffer();
  const totalAmount = await contract.totalAmount();
  const pendingWithdraw = await contract.pendingWithdraw();
  const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);
  if (openOffer && ((amount - pendingWithdraw) < totalAmount)) {
    toast.error("Error: to accept an Open Offer, you must deposit the Total Amount");
    return;
  }
  if ((amount - pendingWithdraw) > totalAmount) {
    toast.error("Error: amount greater than the Total Amount");
    return;
  }
  if (amount > allowance) {
    toast.custom('Note: this asset requires an approval transaction before depositing');
    toast.promise(
      tokenContract.approve(lockerAddress, amount)
        .then((transaction) => {
          return transaction.wait().then(async (tx) => { // Wait for the transaction to be processed
            console.log("Approval Transaction Status:", tx);
            await handleDepositAmountInTokenLocker(lockerAddress, signer, amount); // Call after confirmation
          });
        })
        .catch((error) => {
          console.log("Approval Error: ", error);
          let errorMessage = "Something went wrong!";
          if (error.code === ethers.errors.CALL_EXCEPTION) {
            errorMessage = error.error?.message || error.reason || errorMessage;
          } else if (error.data && error.data.message) {
            errorMessage = error.data.message;
          } else {
            toast.error(errorMessage);
          }
        }),
      {
        loading: "Processing...",
        success: "Successfully approved",
        error: "Something went wrong",
      }
    );
  } else {
    await handleDepositAmountInTokenLocker(lockerAddress, signer, amount);
  }
};

export const depositAmountInTokenLockerWithPermit = async (
  userAddress,
  lockerAddress,
  signer,
  permitData
) => {
  const contract = new ethers.Contract(lockerAddress, TokenLockerABI, signer);
  const openOffer = await contract.openOffer();
  const totalAmount = await contract.totalAmount();
  const pendingWithdraw = await contract.pendingWithdraw();
  if (openOffer && ((permitData.value - pendingWithdraw) < totalAmount)) {
    toast.error("Error: to accept an Open Offer, you must deposit the Total Amount");
    return;
  }
  if ((permitData.value - pendingWithdraw) > totalAmount) {
    toast.error("Error: amount greater than the Total Amount");
    return;
  }
  console.log("permitData:", permitData);
  toast.promise(
    contract
      .depositTokensWithPermit(
        userAddress,
        permitData.value,
        permitData.deadline,
        permitData.v,
        permitData.r,
        permitData.s
      )
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Permit Failed" + (error.message || "Unknown Error");
        toast.error(errorMessage);
      }),
    {
      loading: "Please wait, preparing signature...",
      success: "Signed successfully!",
      error: "Error depositing tokens with permit. Please check your wallet for error messages.",
    }
  );
};

export const depositAmountInEthLocker = async (
  lockerAddress,
  amount,
  signer
) => { };

export const execute = async (address, abi, signer) => {
  const contract = new ethers.Contract(address, abi, signer);
  const sellerApproved = await contract.sellerApproved();
  const buyerApproved = await contract.buyerApproved();
  const totalAmount = await contract.totalAmount();
  const pendingWithdraw = await contract.pendingWithdraw();
  if (!sellerApproved || !buyerApproved) {
    toast.error("Both Buyer and Seller must call Ready to Execute");
    return;
  }
  if (abi === TokenLockerABI) {
    const token = await contract.tokenContract();
    const tokenContract = new ethers.Contract(token, TokenABI, signer);
    const balanceOf = await tokenContract.balanceOf(address);
    if ((balanceOf - pendingWithdraw) < totalAmount) {
      toast.error("Cannot execute until Total Amount is in ChainLocker");
      return;
    }
  } else if (abi === EthLockerABI) {
    const balanceOf = await signer.provider.getBalance(address);
    if ((balanceOf - pendingWithdraw) < totalAmount) {
      toast.error("Cannot execute until Total Amount is in ChainLocker");
      return;
    }
  }
  toast.promise(
    contract
      .execute()
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Enter Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
            error: "Something went wrong!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Execute Failed" + (error.message || "Unknown Error");
        toast.error(errorMessage);
      }),
    {
      loading: "Please wait, connecting wallet...",
      success: "Transaction Approved!",
      error: "Connection error",
    }
  );
};

export const readyToExecute = async (address, abi, signer) => {
  const contract = new ethers.Contract(address, abi, signer);
  toast.promise(
    contract
      .readyToExecute()
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Enter Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
            error: "Something went wrong!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error?.error?.data);
        if (error?.error?.data?.code === 3)
          toast.error(error?.error?.data?.message);
        else if (error?.error?.data?.originalError?.code === 3) {
          toast.error(error?.error?.data?.originalError?.message);
        } else {
          toast.error("Something went wrong!");
        }
      }),
    {
      loading: "Please wait, connecting wallet...",
      success: "Transaction Approved!",
      error: "Connection error",
    }
  );
};

export const withdraw = async (address, abi, signer) => {
  const contract = new ethers.Contract(address, abi, signer);
  const expired = await contract.isExpired()
  let isExpired;
  if (!expired) isExpired = await contract.checkIfExpired();
  console.log("isExpired: ", isExpired);
  toast.promise(
    contract
      .withdraw()
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Transaction Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Withdrawal successful!",
            error: "Something went wrong with the transaction!",
          }
        );
      })
      .catch((error) => {
        console.log("Transaction Error: ", error);
        let errorMessage = "Something went wrong!";
        if (error?.error?.data?.code === 3) {
          errorMessage = error?.error?.data?.message;
        } else if (error?.error?.data?.originalError?.code === 3) {
          errorMessage = error?.error?.data?.originalError?.message;
        }
        toast.error(errorMessage);
      }),
    {
      loading: "Please wait, connecting to the contract...",
      success: "Transaction approved!",
      error: "Connection error",
    }
  );
};


export const rejectDepositor = async (
  address,
  abi,
  signer,
  depositorAddress
) => {
  const contract = new ethers.Contract(address, abi, signer);
  console.log("Buyer Contract:", contract, "Address:", depositorAddress);
  toast.promise(
    contract
      .rejectDepositor(depositorAddress)
      .then((transaction) => {
        toast.promise(
          transaction.wait((tx) => {
            console.log("Enter Status:", tx);
          }),
          {
            loading: "Please wait, transaction processing...",
            success: "Successful!",
            error: "Something went wrong!",
          }
        );
      })
      .catch((error) => {
        console.log("Enter Error: ", error);
        let errorMessage = "Error! Please check the provided address and your wallet";
        if (error.code === ethers.errors.CALL_EXCEPTION) {
          errorMessage = error.error?.message || error.reason || errorMessage;
        } else if (error.data && error.data.message) {
          errorMessage = error.data.message;
        } else {
          toast.error(errorMessage);
        }
      }),
    {
      loading: "Please wait, connecting wallet...",
      error: "Connection error",
    }
  );
};

export const printReceipt = async (address, abi, signer, amount) => {
  if (amount <= 0) {
    toast.error("Error: amount must be greater than zero");
    return;
  }
  const contract = new ethers.Contract(address, abi, signer);
  console.log("Contract Address:", address, "Amount:", amount);

  try {
    const transaction = await contract.getReceipt(amount);
    const toastId = toast.loading("Please wait, receipt printing. Keep this window open to view the result.");
    const receiptTxn = await transaction.wait();
    console.log("Transaction Status:", receiptTxn);
    toast.dismiss(toastId);
    toast.success("Receipt successful!");
    return receiptTxn;
  } catch (error) {
    console.log("Transaction Error: ", error);
    toast.error("Receipt failed", error);
    return null;
  }
};
