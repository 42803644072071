export const ChainLockerFactory = {
  5: "0x0BEeD41753dF1194e32fD50E7dBc662FA2b2BD43", // Goerli
  1: "0x9E444E9F6e248e1a43E1a76C51B685060BeAd3C1", // Mainnet
  1101: "0x9E444E9F6e248e1a43E1a76C51B685060BeAd3C1", // Polygon zkEVM
  137: "0x5005f92fF75c94f95673793D652c013cF645b133", // Polygon PoS / Matic
  10: "0x0BEeD41753dF1194e32fD50E7dBc662FA2b2BD43", // OP Mainnet / optimism
  42161: "0x9E444E9F6e248e1a43E1a76C51B685060BeAd3C1", // Arbitrum One 
};

export const Receipt = {
  5: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // Goerli
  1: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // Mainnet
  1101: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // Polygon zkEVM
  137: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // Polygon PoS / Matic
  10: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // OP Mainnet / optimism
  42161: "0xf838D6829fcCBedCB0B4D8aD58cb99814F935BA8", // Arbitrum One
};
