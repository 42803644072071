import React, { useState, useEffect } from "react";
import Logo from "../assets/LogoLG.png";
import WalletConnectButton from "../components/WalletConnect";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { address } = useAccount();
  const hidden = isChecked ? '' : 'hidden';
  useEffect(() => {
    if (address) navigate("/");
  }, [address, navigate]);
  return (
    <div className="h-screen max-w-4xl mx-auto flex justify-center items-center">
      <div className="mx-6 -mt-10">
        <div
          className="bg-repeat-y bg-contain bg-opacity-10 border-4 border-primary h-44 w-44 mx-auto mb-10 rounded-full hover:cursor-pointer"
          style={{ backgroundImage: `url(${Logo})` }}
          onClick={() => navigate("/")}
        />
        <div className="border border-white rounded-lg">
          <div className="text-center text-2xl font-medium -mt-4 bg-black w-80 mx-auto">
            Create a ChainLocker
          </div>
          <p className="text-center mx-8 my-4">
            Connect your wallet to deploy a custom ChainLocker via your browser.<br /><br />USE AT YOUR OWN RISK: transactions are neither reversible nor alterable by anyone. Only connect a wallet you control directly; do not use an exchange address nor any custodian's address.
          </p>
          <div className="flex items-center justify-center md:text-base mb-8 mx-8 text-justify">
            <input
              checked={isChecked}
              type="checkbox"
              onClick={() => setIsChecked(!isChecked)}
              className="mr-3 cursor-pointer"
            />
            <p className="my-2">
              I agree to ChainLocker’s{" "}
              <span className="text-primary hover:underline hover:cursor-pointer" onClick={() => navigate("/term")}>
                Terms & Conditions.
              </span>
            </p>
          </div>
          <div className=" flex justify-center -mb-4">
            <div className={hidden}>
              <div className="bg-black px-2">
                <WalletConnectButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
