import { ConnectButton } from "@rainbow-me/rainbowkit";
import { GoChevronDown } from "react-icons/go";

function WalletConnectButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    className="flex items-center text-sm font-semibold bg-neutral-900 border border-primary text-white hover:bg-primary border-card shadow-lg px-8 py-2.5 rounded-md"
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="flex items-center text-sm border border-red-600 shadow-md shadow-red-300 px-4 py-1.5 rounded-md"
                  >
                    Wrong network <GoChevronDown className="ml-3" />
                  </button>
                );
              }
              return (
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                    type="button"
                    className="flex items-center text-sm bg-neutral-900 border border-primary text-white hover:bg-primary border-card shadow-md px-4 py-1.5 rounded-md"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 20,
                          height: 20,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 6,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                      </div>
                    )}
                    <span className="hidden sm:block">{chain.name}</span>{" "}
                    <GoChevronDown className="ml-3" />
                  </button>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="flex items-center text-sm bg-neutral-900 border border-primary text-white hover:bg-primary border-card shadow-md px-4 py-1.5  rounded-md"
                  >
                    {account.displayName}
                    <span className="hidden sm:block">
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ""}{" "}
                    </span>
                    <GoChevronDown className="ml-3" />
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

export default WalletConnectButton;
