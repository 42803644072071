import React, { useState } from "react";
import toast from "react-hot-toast";
import { useEventContext } from "../contracts/eventsProvider";
import { EthLockerABI, TokenLockerABI } from "../contracts/abis";
import { readyToExecute, execute, withdraw } from "../utils/useContracts";
import { disconnect } from "wagmi/actions";
import { useMainContext } from "../context/contextProvider";
import UpdateChainButton from "../components/UpdateChain";
import DirectoryDetails from "../layouts/DirectoryDetails";
import { GoCopy } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useEthersSigner } from "../utils/ethersAdapter";

function Directory() {
  const navigate = useNavigate();
  const signer = useEthersSigner();
  const { ChainLockerFactory_DeploymentEvent } = useEventContext();
  const {
    selectedChainLocker,
    setSelectedChainLocker,
    modalStatus,
    setModalStatus,
  } = useMainContext();
  const [filteredArray, setFilteredArray] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const hidden = isChecked ? '' : 'hidden';
  const hiddenafter = isChecked ? 'hidden' : '';

  const filterLockerAddress = (address) => {
    const filtered = ChainLockerFactory_DeploymentEvent.filter(
      (locker) =>
        locker?.args?.chainLockerAddress?.toLowerCase() ===
        address?.toLowerCase()
    );
    if (filtered) setFilteredArray(filtered);
    console.log("Filtered:", filtered);
  };

  const formatAsset = (tokenContract) => {
    let tokenString;
    if (tokenContract ===
      "0x0000000000000000000000000000000000000000" || tokenContract ===
      undefined) {
      tokenString = "Network Token";
    } else {
      tokenString = (tokenContract.toString()).slice(0, 14).concat("...").concat(tokenContract.slice(-5));
    }
    return `${tokenString}      `;
  };

  return (
    <div className="min-h-[60vh] max-w-6xl mx-auto my-20 mb-40 px-6">
      <h5 className="text-2xl text-primary font-light mb-7">
        ChainLocker <strong className="text-white font-semibold">Directory</strong>
      </h5>
      <p className="text-sm">Navigate and interact with ChainLockers deployed via the <span className="cursor-pointer hover:text-cyan-400" onClick={() => navigate("/create")}>Create</span> page.<br /></p>
      {/* Table ======================================================================================== */}
      <div className="flex flex-col">
        <div className="overflow-x-auto shadow-md sm:rounded-md">
          <div className="inline-block min-w-full align-middle">
            <div className="flex justify-between items-center py-3">
              <div className="flex items-center">
                <p className="mr-3 text-gray-400">Search:</p>
                <input
                  type="text"
                  placeholder="ChainLocker Address"
                  onChange={(e) => filterLockerAddress(e.target.value)}
                  className="w-80 border border-[#313131] bg-transparent rounded-md outline-none mr-6 py-1 px-2"
                />
              </div>

              <div className="flex items-center">
                <p className="mr-2 text-gray-400"> Network:</p>
                <div className="md:text-base">
                  <div className={hiddenafter}><input
                    checked={isChecked}
                    type="checkbox"
                    onChange={() => setIsChecked(!isChecked)}
                    className="mr-3 cursor-pointer"
                  />
                    I agree to the{" "}
                    <span className="text-primary hover:underline hover:cursor-pointer" onClick={() => navigate("/term")}>
                      Terms & Conditions.
                    </span>
                  </div>
                </div>
              </div>
              <div className={hidden}><div className="flex justify-center">
                <UpdateChainButton />
              </div><p><span className="text-sm text-gray-400 cursor-pointer hover:text-cyan-400" onClick={() => disconnect()}>Disconnect Wallet</span></p>
              </div>
            </div>
            {/* table */}
            <div className="overflow-hidden rounded">
              <table className="min-w-full divide-y table-fixed divide-[#313131] border border-[#313131] rounded">
                <thead className="bg-[#313131]">
                  <tr>
                    <th scope="col" className="p-4">
                      {/* <div className="flex items-center">
                        <input
                          id="checkbox-all"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded-md border-gray-300 dark:bg-[#313131] dark:border-gray-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox-all" className="sr-only">
                          checkbox
                        </label>
                      </div> */}
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      ChainLocker Address
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase"
                    >
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      Creator Address
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase"
                    >
                      Asset
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#313131] dark:bg-neutral-900 dark:divide-[#313131] rounded">
                  {(filteredArray.length
                    ? filteredArray
                    : ChainLockerFactory_DeploymentEvent
                  )?.map((event, index) => (
                    <tr
                      key={"Dipevent" + index}
                      className="hover:bg-gray-100/10 dark:hover:bg-gray-100/10 rounded-md"
                    >
                      <td className="p-4 w-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-1"
                            type="checkbox"
                            checked={
                              selectedChainLocker?.chainLockerAddress ===
                              event?.args?.chainLockerAddress
                            }
                            onChange={() => { }}
                            onClick={() => setSelectedChainLocker(event?.args)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:bg-[#313131] dark:border-gray-600 cursor-pointer"
                          />
                          <label htmlFor="checkbox-table-1" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-sm font-medium  whitespace-nowrap dark:text-white flex items-center">
                        {event?.args?.chainLockerAddress
                          ?.slice(0, 14)
                          .concat("...")
                          .concat(
                            event?.args?.chainLockerAddress?.slice(-5)
                          )}{" "}
                        <GoCopy
                          onClick={() => {
                            navigator.clipboard.writeText(
                              event?.args?.chainLockerAddress
                            );
                            toast.success("ChainLocker Address Copied!");
                          }}
                          className="ml-2 cursor-pointer"
                        />
                      </td>
                      <td className="py-4 px-6 text-sm font-mediumtext-[#313131] whitespace-nowrap dark:text-white">
                      </td>
                      <td className="py-4 px-6 text-sm font-medium  whitespace-nowrap flex dark:text-white">
                        {event?.args?.deployer?.slice(0, 14)
                          .concat("...")
                          .concat(
                            event?.args?.deployer?.slice(-5)
                          )}{" "}
                        <GoCopy
                          onClick={() => {
                            navigator.clipboard.writeText(
                              event?.args?.deployer
                            );
                            toast.success("Deployer Address Copied!");
                          }}
                          className="ml-2 cursor-pointer"
                        />
                      </td>
                      <td className="py-4 px-6 text-sm font-medium  whitespace-nowrap dark:text-white">
                        {event?.args?.tokenContract ===
                          "0x0000000000000000000000000000000000000000"
                          ? "Network Token"
                          : formatAsset(event?.args?.tokenContract)}
                        {event?.args?.tokenContract !==
                          "0x0000000000000000000000000000000000000000" && (
                            <GoCopy
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  event?.args?.tokenContract
                                );
                                toast.success("Token Address Copied!");
                              }}
                              className="ml-2 cursor-pointer inline"
                            />
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Details */}
      {selectedChainLocker && (
        <DirectoryDetails selectedChainLocker={selectedChainLocker} />
      )}
      {/* Main Func */}
      <div className="grid grid-cols-6 gap-4 mt-4">
        {/* Update Buyer ---------------------------------------------- */}
        <button
          onClick={() =>
            selectedChainLocker
              ? setModalStatus({ ...modalStatus, updateBuyerModal: true })
              : toast.error("Please Select ChainLocker First!")
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Update Buyer
        </button>
        {/* Update Seller --------------------------------------------- */}
        <button
          onClick={() =>
            selectedChainLocker
              ? setModalStatus({ ...modalStatus, updateSellerModal: true })
              : toast.error("Please Select ChainLocker First!")
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Update Seller
        </button>
        <div className="col-span-6 md:col-span-2" />
        {/* Withdraw ====================================================================== */}
        <button
          onClick={() =>
            withdraw(
              selectedChainLocker?.chainLockerAddress,
              selectedChainLocker?.tokenContract ===
                "0x0000000000000000000000000000000000000000"
                ? EthLockerABI
                : TokenLockerABI,
              signer
            )
          }
          className=" h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Withdraw
        </button>
        <button
          onClick={() =>
            execute(
              selectedChainLocker?.chainLockerAddress,
              selectedChainLocker?.tokenContract ===
                "0x0000000000000000000000000000000000000000"
                ? EthLockerABI
                : TokenLockerABI,
              signer
            )
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Execute
        </button>
        <button
          onClick={() =>
            selectedChainLocker
              ? setModalStatus({ ...modalStatus, depositAmountModal: true })
              : toast.error("Please Select ChainLocker First!")
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Deposit
        </button>
        {/* Ready to Execute --------------------------------------------- */}
        <button
          onClick={() =>
            readyToExecute(
              selectedChainLocker?.chainLockerAddress,
              selectedChainLocker?.tokenContract ===
                "0x0000000000000000000000000000000000000000"
                ? EthLockerABI
                : TokenLockerABI,
              signer
            )
          }
          className=" h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Ready to Execute
        </button>
        <div className="col-span-6 md:col-span-2" />
        <button
          onClick={() =>
            selectedChainLocker
              ? setModalStatus({ ...modalStatus, rejectDepositorModal: true })
              : toast.error("Please Select ChainLocker First!")
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Reject Depositor
        </button>
        <button
          onClick={() =>
            selectedChainLocker
              ? setModalStatus({ ...modalStatus, printReceiptModal: true })
              : toast.error("Please Select ChainLocker First!")
          }
          className="h-[38px] mt-auto bg-neutral-900 border border-primary text-white hover:bg-primary px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
        >
          Print Receipt
        </button>
      </div>
      <p className="text-sm text-neutral-500"><br /><br /><br />Note: ChainLockers take a varying length of time to appear and update in the Directory depending on the Network and your Wallet client.<br /><br />
        In the event of delay or error, ChainLockers may also be accessed and used via the applicable Network's block explorer. <br /><br />
        All information displayed in this Directory is publicly accessible on the applicable Network.</p>
    </div>
  );
}

export default Directory;
