import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Landing from "../pages/Landing";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Create from "../pages/Create";
import Directory from "../pages/Directory";
import Documentation from "../pages/Documentation";
import RequestConsult from "../pages/RequestConsult";
import Modals from "../layouts/Modals";
import TermsCondition from "../pages/TermsCondition";

function HomeRoutes() {
  return (
    <React.Fragment>
      <Modals />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create" element={<Create />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/consult" element={<RequestConsult />} />
        <Route path="/term" element={<TermsCondition />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

function Router() {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/start" />} /> */}
      {/* Landing Page ---------------------------------------------------------------- */}
      <Route path="/start" element={<Landing />} />
      {/* Home ------------------------------------------------------------------------ */}
      <Route path="/*" element={<HomeRoutes />} />
    </Routes>
  );
}

export default Router;
