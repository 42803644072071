import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useMainContext } from "../../context/contextProvider";
import { handleDepositClick } from "../../utils/useContracts";
import { TokenABI, TokenLockerABI, ERC20PermitABI } from "../../contracts/abis";
import { useContractRead, useAccount } from "wagmi";
import { ethers } from "ethers";
import { useEthersSigner } from "../../utils/ethersAdapter";

export default function DepositAmountModal() {
  const signer = useEthersSigner();
  const { address } = useAccount();
  const {
    modalStatus,
    setModalStatus,
    selectedChainLocker,
    formData,
    setFormData,
  } = useMainContext();

  function closeModal() {
    setModalStatus({ ...modalStatus, depositAmountModal: false });
  }

  const formatTokenAmount = (amount, decimals) => {
    if (!amount || isNaN(amount)) {
      return "0";
    }
    return ethers.utils.parseUnits(amount, decimals).toString();
  };

  const { data: decimals } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "decimals",
  });

  const { data: symbol } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "symbol",
  });

  const { data: allowance } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: 'allowance',
    args: [address, selectedChainLocker?.chainLockerAddress],
  });

  const { data: expirationTime } = useContractRead({
    address: selectedChainLocker?.chainLockerAddress,
    abi: TokenLockerABI,
    functionName: "expirationTime",
  });

  const { data: domainSeparator } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: ERC20PermitABI,
    functionName: "DOMAIN_SEPARATOR",
  });

  const { data: permitTypehash } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: ERC20PermitABI,
    functionName: "PERMIT_TYPEHASH",
  });

  const { data: nonces } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: ERC20PermitABI,
    functionName: "nonces",
    args: [address]
  });

  console.log("allowance: ", allowance);
  console.log("expiration: ", expirationTime);
  console.log("signer: ", signer);
  console.log("tokenContract:", selectedChainLocker?.tokenContract);
  console.log("depositAmount:", formData.depositAmount);

  return (
    <Transition appear show={modalStatus.depositAmountModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Deposit to selected ChainLocker{" "}
                </Dialog.Title>

                <div className="flex justify-between items-center">
                  <p className="text-xs text-black mt-4">
                    <span className="text-primary">Auto-formatted Amount: </span><span className="text-black/90">{formData.depositAmount} {symbol}</span>
                  </p>
                  <p className="text-xs text-black">
                    {/* {decimals &&
                        decimals !== "" &&
                        ethers.utils.parseUnits("1", decimals)} */}
                  </p>
                </div>

                <div className="col-span-4 md:col-span-2 mb-4 mt-2">
                  <input
                    type="number"
                    name="depositAmount"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        depositAmount: formatTokenAmount(e.target.value, decimals),
                      })
                    }
                    placeholder={"Enter amount of asset to deposit"}
                    className="w-full text-black border border-black bg-transparent rounded-md outline-none px-2 py-1.5"
                  />
                </div>
                <button
                  onClick={() =>
                    handleDepositClick(
                      selectedChainLocker?.tokenContract,
                      selectedChainLocker?.chainLockerAddress,
                      signer,
                      formData.depositAmount,
                      allowance,
                      expirationTime,
                      domainSeparator,
                      permitTypehash,
                      nonces
                    )
                  }
                  className="h-[38px] w-full mt-auto bg-primary text-white px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
                >
                  Deposit{" "}
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
