import { createContext, useContext, useEffect, useState } from "react";
import { useContractRead, useNetwork, useWalletClient } from "wagmi";
import { getContract } from "wagmi/actions";
import { ChainLockerFactoryABI } from "./abis";
import { ChainLockerFactory } from "./addresses";

const FactoryContext = createContext();

export const FactoryProvider = ({ children }) => {
  const { chain, chains } = useNetwork();
  const { data: walletClient, isError } = useWalletClient();
  const [factory, setFactory] = useState({});
  const [chainId, setChainId] = useState(null);

  console.log("Chain:", chain, "Chains:", chains);
  console.log("ChainLockerFactory Object:", ChainLockerFactory);

  const { data: minimumFee } = useContractRead({
    address: ChainLockerFactory[chainId],
    abi: ChainLockerFactoryABI,
    functionName: "minimumFee",
  });

  useEffect(() => {
    if (chain) {
      setChainId(chain.id);
    }
  }, [chain]);

  useEffect(() => {
    console.log("walletClient:", walletClient);
    console.log("chain:", chain);
    console.log("isError:", isError);

    if (isError) return;
    if (walletClient) {
      const ChainLockerFactoryAddress = ChainLockerFactory[chainId];
      console.log("Current Address:", ChainLockerFactoryAddress);

      if (!ChainLockerFactoryAddress) {
        console.error("No contract address found for the current network.");
        return;
      }

      const FactoryContract = getContract({
        address: ChainLockerFactoryAddress,
        abi: ChainLockerFactoryABI,
        walletClient
      });
      console.log("FactoryContract:", FactoryContract);
      setFactory(FactoryContract);
    }
  }, [walletClient, chain, chainId, isError]);

  return (
    <FactoryContext.Provider value={{ factory, minimumFee }}>
      {children}
    </FactoryContext.Provider>
  );
};

export const useFactoryContext = () => {
  return useContext(FactoryContext);
};
