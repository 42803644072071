import React from "react";
import toast from "react-hot-toast";

function RequestConsult() {
  return (
    <div className="max-w-4xl mx-auto px-6">
      <h5 className="text-2xl text-primary font-light mt-20">
        Request <strong className="text-white font-semibold">Consult</strong>
      </h5>
      <p className="mt-3 text-start"><br />Request personalized assistance with:<br /><br />
        <li className="px-10">implementation designs and proofs of concept</li>
        <li className="px-10">demonstrations and training</li>
        <li className="px-10">contract parameter inputs and verification</li>
        <li className="px-10">custom value condition data feeds</li>
        <li className="px-10">additional EVM network support</li>
        <li className="px-10">third party service integrations and audits</li>
        <li className="px-10">bespoke documentation, and more.</li><br />
        Please email <span className="cursor-pointer hover:text-cyan-400 text-primary" onClick={() => {
          navigator.clipboard.writeText("contact@chainlocker.xyz");
          toast.success("Email Address Copied!");
        }} >contact@chainlocker.xyz</span> to receive a price quote and schedule your consult.<br />
      </p>
    </div>
  );
}

export default RequestConsult;
