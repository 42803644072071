import { createContext, useContext, useState } from "react";

const MainContext = createContext();

export const FunctionsProvider = ({ children }) => {
  const [modalStatus, setModalStatus] = useState({
    updateBuyerModal: false,
    updateSellerModal: false,
    depositAmountModal: false,
    printReceiptModal: false,
    receiptResultModal: false,
    rejectDepositorModal: false,
    transactionModal: false,
  });
  const [selectedChainLocker, setSelectedChainLocker] = useState(null);
  const [formData, setFormData] = useState({
    buyerAddress: "",
    sellerAddress: "",
    depositAmount: 0,
    receiptAmount: 0,
    receiptTransaction: {},
    paymentId: 0,
    usdValue: 0,
    printedReceipt: 0,
    rejectDepositorAddress: "",
    transaction: {},
  });
  console.log("Selected ChainLocker:", selectedChainLocker);

  return (
    <MainContext.Provider
      value={{
        selectedChainLocker,
        setSelectedChainLocker,
        formData,
        setFormData,
        modalStatus,
        setModalStatus,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};
