import React from "react";

function TermsCondition() {
  return (
    <div className="max-w-6xl mx-auto my-20 px-6">
      <h5 className="text-2xl text-primary font-light mb-7">
        Terms &
        <strong className="text-white font-semibold"> Conditions</strong>
      </h5>
      <p className="mt-3 font-light text-justify">
        <strong className="text-neutral-500">Last Updated: 30 November 2023</strong>
        <br /><br />
        Please read these Terms and Conditions ("Terms") carefully before using chainlocker.org, chainlocker.xyz, and any subdomains and other websites, links, files, information, code, smart contracts, interfaces, integrations, and other materials set forth, developed, deployed, operated, or maintained by ChainLocker LLC, a Delaware Limited Liability Company (“ChainLocker”, "Us", "We", or "Our") (the "Services"). By accessing, using, or participating in any of the Services, you accept and agree to be bound by and to comply with these Terms. If you do not agree to, or are not in compliance with, these Terms, you must stop using and avoid accessing or using the Services. We may update the Services and any part of the Terms at any time, for any reason, at our sole discretion. Once any part of the Terms is updated and in effect, you will be bound by the Terms if you continue to use or access any of the Services. We may, at any time, and without liability to you, modify or discontinue all or part of the Services (including access to the Services via any third-party links).
        <br /><br />
        Your access to and use of any of the Services is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use a Service.
        <br /><br />
        ChainLocker is not a registered escrow agent nor money transmitter nor broker nor transfer agent nor swap dealer nor trading facility nor financial institution. ChainLocker does not provide escrow, custody, trading, exchange, clearing, nor money transmission services; it provides code and information that you may use to deploy your own smart contracts. ChainLocker has no oversight, involvement, or control with respect to your transactions nor Network Messages (as such term is defined herein) pursuant to the Services or any Third Party Services (as such term is defined herein). Absent a written and mutually signed agreement to the contrary, any messages or data files constructed or suggested by ChainLocker or a Service are informational in nature only, and it is your responsibility to verify them before using such information to initiate any transaction or Network Message. You are responsible for complying with all applicable law and regulations applicable to you and your transactions and Network Messages. ChainLocker does not own or control the underlying blockchain protocols and networks that are used in connection with the Services nor any smart contracts you may deploy while using the Services, and ChainLocker is not responsible for their operation nor their functionality, security, or availability.
        <br /><br />
        You represent to us that you are lawfully able to enter into contracts. If you are agreeing to these Terms for an entity, such as the company you work for, you represent to us that you have legal authority to bind that entity. In addition, you represent to Us that you and your financial institutions, or any party that owns or controls you or your financial institutions, or your controlled property or accounts, are (1) not subject to sanctions or otherwise designated on any list of prohibited or restricted parties set forth by the United States Government (i.e., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of Treasury and the Entity List of the U.S. Department of Commerce), and (2) not located in any country subject to a comprehensive sanctions program implemented by the United States. The Services have certain programmatic barriers against payment to ChainLocker by known sanctioned addresses, and ChainLocker may require users of the Services to undertake further screening processes according to the latest applicable regulatory guidance and legal requirements. ChainLocker reserves the right to update such screenings or barriers from time to time without notice to users of the Services.
        <br /><br />
        <strong className="text-primary ml-6">Publicly Exposed Data</strong>
        <br /><br />
        Please be advised that the contents of your messages, transactions, and signatures that you expose or provide to blockchain networks or related third parties for ultimate provision thereto (including but not limited to related RPC services, nodes, clients, mempools, wallet providers, miners, validators, or similar, as applicable) in using, or in relation to using, the aspects of the Services that interact with blockchain networks (“Network Messages”) are typically public.
        <br /><br />
        ChainLocker does not collect or use any data other than what (1) is publicly accessible in relation to Network Messages and (2) you email or otherwise communicate to us.
        <br /><br />
        <strong className="text-primary ml-6">Third Party Services</strong>
        <br /><br />
        Our Services may contain links, widgets, plugins, or other interfaces to third-party websites or services that are not owned or controlled by ChainLocker. We have no control or influence over, do not represent or warrant the offerings of, and assume no responsibility for, the execution, quality, accuracy, content, privacy policies, or practices of any third-party websites, products, or services including, without limitation, any network, interface, software, plugin or application (“Third Party Services”). The use and access of any Third Party Services, including through the Services, is at your own risk. We have no control or influence over, and assume no responsibility for, the execution of Network Messages.
        <br /><br />
        You acknowledge and agree that ChainLocker is not responsible nor liable, directly or indirectly, for any damage, harm, or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, information, goods, or services available on or through any Third Party Services or that you have initiated in a Network Message.
        <br /><br />
        <strong className="text-primary ml-6">Assumption of Risks</strong>
        <br /><br />
        <strong>You acknowledge the risks of using the Services, including but not limited to that Network Messages related to the Services are irreversible, final, and cannot be refunded by any party. You bear sole responsibility for your own Network Messages and generally evaluating the Services before using them.</strong>
        <br /><br />
        ChainLocker does not provide any RPC, node, nor block-building services; therefore ChainLocker does not and cannot process any Network Messages. <strong>All transactions, signatures, and Network Messages You sign and confirm are via your own software or a Third Party Service you elect, over which ChainLocker has no control and neither assumes nor incurs liability.</strong>
        <br /><br />
        The Services may be disabled, disrupted or adversely impacted as a result of sophisticated exploits or cyber-attacks, surges in activity, viruses, or other operational or technical challenges, errors, or unanticipated occurrences. We disclaim any ongoing obligation to notify you of the potential risks of using and accessing our Services. You agree to accept these risks and agree that you will not seek to hold ChainLocker, any member or affiliate of ChainLocker, nor any of ChainLocker’s or any member or affiliates of ChainLocker’s owners, advisors, employees, contractors or agents (each, an “Indemnitee” and together the “Indemnitees”) responsible for any harm or losses which may arise from or in relation to your use of, or your relation to a use of, any of the Services or a Network Message.
        <br /><br />
        You understand and agree that you are solely responsible for maintaining the security of your accounts, passwords, and private keys. Any unauthorized access to your account by third parties could result in the loss or theft of any cryptoasset, or any funds held in your account and any associated accounts. You understand and agree that ChainLocker has no involvement in, and you will not hold ChainLocker responsible nor liable for, managing and maintaining the security of your accounts or private keys-- it is your responsibility to monitor and secure your own information and accounts.
        <br /><br />
        We do not guarantee the quality or accessibility of the Services. As a condition to accessing or using the Services you acknowledge, understand, and agree that from time to time, any Service may be inaccessible or inoperable for any reason, including, but not limited to malfunctions, periodic maintenance, upgrades or re-deployments, causes beyond ChainLocker’s control or that we could not reasonably foresee, disruptions and temporary or permanent unavailability of underlying blockchain infrastructure or unavailability of any Third Party Service.
        <br /><br />
        You acknowledge and agree that you will access and use the Services, including, without limitation, initiating of all Network Messages, at your own risk. You should not engage in blockchain-based transactions unless it is suitable given your circumstances and financial resources. By using the Services, you represent that you have been, are and will be solely responsible for conducting your own due diligence into the risks of a transaction and the underlying smart contracts, networks, and cryptocurrencies, cryptographic tokens, or other digital assets (“cryptoassets”).
        <br /><br />
        You assume the risks of engaging in transactions that rely on blockchain smart contracts and other experimental and risky technology. The Services substantially rely on smart contract deployments on various blockchains, cryptoassets, and other nascent software, applications and systems that interact with blockchain networks. Please note the following inexhaustive list of risks that could disrupt these technologies and even result in a total loss of digital funds or cryptoassets, or their market value, which you assume:
        <br /><br />
        <li className="ml-6">Code vulnerabilities, bugs, malfunctions: Bugs or vulnerabilities in the Services or Third Party Services on which they depend can lead to unintended consequences, loss of funds, or exploitation by malicious actors. No software product can be definitively free of vulnerabilities.</li><br />
        <li className="ml-6">Limited recourse: Many of such networks and the applicable smart contracts thereon (including many of those used by Services) are immutable and ownerless, meaning ChainLocker is unable to control, alter, cancel, delete, or otherwise affect them. In case of certain issues or vulnerabilities, the irreversible nature of blockchain may make recovery of assets impractical or impossible, and ChainLocker has no responsibility nor liability for any smart contract whose deployment was initiated by, or which is used by, you (whether via the Services or any other interface).</li><br />
        <li className="ml-6">Oracle reliance: When smart contracts rely on external data sources (via oracles) to trigger certain actions (such as a “value condition”), there is a risk that the oracle data may be inaccurate or manipulated, which could lead to unintended outcomes in the smart contract execution.</li><br />
        <li className="ml-6">Regulatory uncertainty: The legal and regulatory landscape around blockchain and smart contracts is still evolving, which may create uncertainty around their usage and the enforceability of corresponding legal agreements and disputes involving cryptoassets, and may affect the value of your assets used in the Services.</li><br />
        <li className="ml-6">Network congestion: Blockchain networks periodically face network congestion or scalability issues, resulting in slow transaction times or increased costs, which could impact the efficiency of smart contract transactions.</li><br />
        <li className="ml-6">Privacy concerns: Transactions on public blockchains are generally publicly viewable, which may raise privacy concerns for parties using ChainLocker. You are encouraged to take steps to preserve your privacy.</li><br />
        <li className="ml-6">Cryptoasset volatility: by using cryptoassets in your use of the Services, you are exposed to their inherent volatility, which could result in fluctuations in the value of the locked cryptoassets and any underlying transaction.</li><br />
        <li className="ml-6">User error: You control material aspects with respect to your use of the Services and you may make mistakes while interacting with smart contracts, such as inputting incorrect addresses or parameters or improper interpretation of interfaces, code, or other aspects of the Services or Third Party Services, which could lead to loss of funds or unintended outcomes.</li><br />
        <li className="ml-6">Phishing/Spoofing attacks: Malicious actors may emulate the ChainLocker website or software in attempt to coerce users into signing harmful transactions which may lead to a loss of cryptoassets or otherwise acquiring sensitive or confidential information.</li>
        <br /><br />
        We assume no liability or responsibility for any such risks. If you are not comfortable assuming these risks, you should not access or engage in transactions using blockchain-based technology nor use the Services.
        <br /><br />
        NETWORK MESSAGES, INCLUDING TRANSFERS OF CRYPTOASSETS AND DATA INPUTS (INCLUDING REQUESTED ORACLE-FED DATA FROM EXTERNAL THIRD PARTY APIS), MUST BE TREATED AS PERMANENT AND CANNOT BE UNDONE BY ANYONE. YOU MUST BE VERY CAREFUL WHEN YOU FINALIZE AND INITIATE ANY NETWORK MESSAGE.
        <br /><br />
        <strong className="text-primary ml-6">Peer-to-Peer and Immutable Transactions</strong>
        <br /><br />
        You acknowledge and agree that Network Messages may be automatically processed using one or more Third Party Services and blockchain-based smart contracts, which are neither reversible nor alterable by ChainLocker. By initiating a Network Message using the Services, you acknowledge and consent to the automatic processing of all Network Messages in connection with using the Services including but not limited to the automated collection and disbursement of cryptoassets by smart contracts.
        <br /><br />
        The Services provide instructions on how, or otherwise enable you, to send supported cryptoassets to, and request, receive, and store supported cryptoassets from, smart contracts and third parties by giving instructions through the Services. When you or a third party sends cryptoassets to a smart contract, the person initiating the Network Message is solely responsible for ensuring its proper execution, which may include, among other things, payment of sufficient network or miner’s fees for it to be successful. Insufficient network fees may cause a Network Message to remain in a pending state and may result in delays or loss incurred because of an error in the initiation of the Network Message. ChainLocker has no obligation nor ability to assist in the remediation of such Network Message. You should verify all Network Messages prior to submitting them. ChainLocker shall bear no liability or responsibility in the event you enter an incorrect parameter or detail in a Network Message. Network Messages cannot be reversed once they have been broadcast to the relevant cryptocurrency network, although they may be in a pending state, and designated accordingly, while the Network Message is processed. ChainLocker does not control any network and makes no guarantees that a Network Message will be confirmed by the applicable or intended network.
        <br /><br />
        <strong className="text-primary ml-6">Fees</strong>
        <br /><br />
        By using the Services, you agree to pay all applicable fees, including transaction fees for Network Messages or service fees for using the Services, or other third party fees whether in the Services or in any Third Party Service used in relation to a Service. You will also incur a network and/or gas fee, which is the fee incurred when a user initiates a Network Message, a “native gas” fee. It is charged by and paid to the blockchain network for facilitating the transaction, not ChainLocker. It is your sole responsibility to determine whether, and to what extent, any taxes apply to any Network Message via the Services, and to withhold, collect, report and remit the correct amount of tax to the appropriate tax authorities. All fees payable by you are exclusive of taxes unless otherwise noted. All fees are displayed and current as reflected in the Services and may be updated and changed from time to time by ChainLocker.
        <br /><br />
        Any consulting services (whether undertaken by ChainLocker LLC or referred) are expressly discretionary, subject to availability, and are contingent upon entering into a mutually executed written agreement between you and the applicable party providing such services. Any code, templates or form agreements, or other files are provided without representations, warranties, guarantees, promises, or legal advice of any kind, and should not be relied upon without the review and advice of legal counsel for your unique facts and circumstances.
        <br /><br />
        <strong className="text-primary ml-6">Prohibited Use</strong>
        <br /><br />
        In connection with your use of the Services, and your interactions with other users, including but not limited to your use and interaction with or through third party persons, software, networks, or services in relation to the Services, you agree and represent you will not engage in any unlawful, abusive, or intentionally misrepresentational activity, fraud, gambling, intellectual property infringement, or legally or governmentally prohibited activities via or in relation to the Services.
        <br /><br />
        <strong className="text-primary ml-6">Indemnification</strong>
        <br /><br />
        You shall defend, indemnify, and hold harmless the Indemnitees from any claim, demand, lawsuit, action, proceeding, investigation, liability, damage, loss, costs or expenses, including without limitation reasonable attorneys’ fees, arising out of or relating to your use of, or conduct in connection with, the Services, your content, your violation of these Terms, your violation of any applicable law or regulation, your negligence or misconduct, or your infringement or misappropriation of the rights of any other person or entity. If you are obligated to indemnify any Indemnitee, ChainLocker (or, at its discretion, the applicable Indemnitee) will have the right, in its sole discretion, to control any legal action or proceeding arising therefrom.
        <br /><br />
        <strong className="text-primary ml-6">Disclaimer</strong>
        <br /><br />
        To the maximum extent permitted under applicable law, the Services (and any of their content or functionality) provided by or on behalf of ChainLocker are provided on an “AS IS” basis, and We expressly disclaim, and you hereby waive, any representations, conditions and warranties of any kind, whether express or implied, legal, statutory or otherwise, or arising from statute, otherwise in law, course of dealing, or usage of trade, including, without limitation, the implied or legal warranties and conditions of merchantability, quality or fitness for a particular purpose, title, security, availability, reliability, accuracy, quiet enjoyment and non-infringement of third party rights. Without limiting the foregoing, we do not represent or warrant that the Services will be uninterrupted, available at any time, or error-free. We do not warrant that errors in the Services are correctable or will be corrected beyond good faith measures by us, to the extent possible and practicable.
        <br /><br />
        <strong className="text-primary ml-6">Limitation of Damages and Liability</strong>
        <br /><br />
        You acknowledge and agree that, to the maximum extent permitted under applicable law, ChainLocker, together with any Indemnitee, will not in any event be liable for any incidental, indirect, special, punitive, exemplary, consequential or similar damages or liabilities whatsoever (including, without limitation, damages for loss of data, information, revenue or other business or financial benefit) arising out of or in connection with any of the Services (and any of their content and functionality), any execution or settlement of a transaction, any performance or non-performance of the Services, your Network Messages or cryptoassets, or any other product, service or other item provided by or on behalf of an Indemnitee, whether under contract, tort (including negligence), civil liability, statute, strict liability, breach of warranties, or under any other theory of liability, and whether or not any Indemnitee has been advised of, knew of or should have known of the possibility of such damages and notwithstanding any failure of the essential purpose of these Terms or any limited remedy nor is ChainLocker in any way responsible for the execution or settlement of transactions between users of the Services.
        <br /><br />
        You acknowledge and agree that, to the maximum extent permitted under applicable law, ChainLocker, together with any Indemnitee, will not be liable for any loss or damage caused by denial-of-service attacks, 51% attacks, software or data failures or manipulation or exploits, lost private or public keys, viruses or other technologically harmful materials (including those which may infect your equipment or software), Third Party Services, changes by third party providers or Third Party Services, outages, force majeure events or other disasters, scheduled or unscheduled maintenance, or other causes outside our control.
        <br /><br />
        Absent a written mutually signed agreement with ChainLocker, You do not have privity of contract with ChainLocker for your use of a Service, including but not limited to any of the Services which are not substantially controlled by ChainLocker (including but not limited to smart contracts deployed on a public blockchain network, algorithmic or automatic or functionally autonomous software, or any other software deployed upon, hosted upon, or substantially dependent upon an unaffiliated person or network or other decentralized software).
        <br /><br />
        In no event will the Indemnitees' aggregate liability arising out of or in connection with the Services (and any of their content and functionality), any performance or non-performance of the Services, your Network Messages or cryptoassets, or any other product, service or other item provided by or on behalf of an Indemnitee, whether under contract, tort (including negligence), civil liability, statute, strict liability or other theory of liability exceed the amount of the fees paid by you to ChainLocker under these Terms in the one year period immediately preceding the event giving rise to the claim for liability.
        <br /><br />
        <strong className="text-primary ml-6">Governing Law and Arbitration</strong>
        <br /><br />
        These Terms and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these Terms or a Service or related subject matter shall be governed by and construed in accordance with the laws of the State of Delaware. The Federal Arbitration Act and federal arbitration law apply to these Terms. The arbitration will be conducted in accordance with the expedited procedures set forth in the JAMS Comprehensive Arbitration Rules and Procedures (the “Rules”) as those Rules exist on the date these Terms were last updated, including Rules 16.1 and 16.2 of those Rules. The arbitrator’s decision shall be final, binding, and non-appealable. Judgment upon the award may be entered and enforced in any court having jurisdiction. The arbitrator, and not any federal, state, or local court, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, unconscionability, arbitrability, enforceability of these Terms including any claim that all or any part of the foregoing that is void or voidable.
        <br /><br />
        If any portion of these Terms is found to be unenforceable or unlawful for any reason, the unenforceable or unlawful provision will be severed from these Terms, and the severance of the unenforceable or unlawful provision will have no impact whatsoever on the remainder or the parties’ ability to compel arbitration of any remaining claims on an individual basis, and to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims will be stayed pending the outcome of any individual claims in arbitration.
        <br /><br />
        Any claim arising out of or related to these Terms or the Services must be filed within one year after the nexus for such claim arose; otherwise, the claim is permanently barred, which means that you or ChainLocker, as applicable, will not have the right to assert the claim. You have the right to opt out of binding arbitration within 30 days of the date you first accepted these Terms by providing notice to us in the manner indicated in the “Notices” section below.
        <br /><br />
        <strong className="text-primary ml-6">Notices</strong>
        <br /><br />
        To give Us notice under these Terms, you must contact ChainLocker by email at contact@chainlocker.xyz, and mail a copy of such notice to:
        <br /><br />
        ChainLocker LLC<br />
        611 South DuPont Highway<br />
        Suite 102<br />
        Dover, DE 19901<br />
      </p>
    </div>
  );
}

export default TermsCondition;
