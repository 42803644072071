export const TokenABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  }
];

export const ERC20PermitABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner"
      },
      {
        internalType: "address",
        name: "spender"
      },
      {
        internalType: "uint256",
        name: "value"
      },
      {
        internalType: "uint256",
        name: "deadline"
      },
      {
        internalType: "uint8",
        name: "v"
      },
      {
        internalType: "bytes32",
        name: "r"
      },
      {
        internalType: "bytes32",
        name: "s"
      }
    ],
    name: "permit",
    stateMutability: "nonpayable",
    outputs: [],
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

export const ChainLockerFactoryABI = [
  { inputs: [], stateMutability: "payable", type: "constructor" },
  { inputs: [], name: "ChainLockerFactory_FeeMissing", type: "error" },
  { inputs: [], name: "ChainLockerFactory_OnlyReceiver", type: "error" },
  { inputs: [], name: "ChainLockerFactory_ZeroInput", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "deployer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "chainLockerAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenContract",
        type: "address",
      },
    ],
    name: "ChainLockerFactory_Deployment",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "feeAmount",
        type: "uint256",
      },
    ],
    name: "ChainLockerFactory_FeePaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bool", name: "feeSwitch", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "newFeeDenominator",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newMinimumFee",
        type: "uint256",
      },
    ],
    name: "ChainLockerFactory_FeeUpdate",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newReceiver",
        type: "address",
      },
    ],
    name: "ChainLockerFactory_ReceiverUpdate",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptReceiverRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bool", name: "_refundable", type: "bool" },
      { internalType: "bool", name: "_openOffer", type: "bool" },
      {
        internalType: "enum ChainLockerFactory.ValueCondition",
        name: "_valueCondition",
        type: "uint8",
      },
      { internalType: "int224", name: "_maximumValue", type: "int224" },
      { internalType: "int224", name: "_minimumValue", type: "int224" },
      { internalType: "uint256", name: "_deposit", type: "uint256" },
      { internalType: "uint256", name: "_totalAmount", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address payable", name: "_seller", type: "address" },
      { internalType: "address payable", name: "_buyer", type: "address" },
      { internalType: "address", name: "_tokenContract", type: "address" },
      {
        internalType: "address",
        name: "_dataFeedProxyAddress",
        type: "address",
      },
    ],
    name: "deployChainLocker",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "feeDenominator",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeSwitch",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minimumFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "receiver",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bool", name: "_feeSwitch", type: "bool" },
      { internalType: "uint256", name: "_newFeeDenominator", type: "uint256" },
      { internalType: "uint256", name: "_newMinimumFee", type: "uint256" },
    ],
    name: "updateFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newReceiver",
        type: "address",
      },
    ],
    name: "updateReceiver",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const TokenLockerABI = [
  {
    inputs: [
      { internalType: "bool", name: "_refundable", type: "bool" },
      { internalType: "bool", name: "_openOffer", type: "bool" },
      { internalType: "uint8", name: "_valueCondition", type: "uint8" },
      { internalType: "int224", name: "_maximumValue", type: "int224" },
      { internalType: "int224", name: "_minimumValue", type: "int224" },
      { internalType: "uint256", name: "_deposit", type: "uint256" },
      { internalType: "uint256", name: "_totalAmount", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "address", name: "_buyer", type: "address" },
      { internalType: "address", name: "_tokenContract", type: "address" },
      {
        internalType: "address",
        name: "_dataFeedProxyAddress",
        type: "address",
      },
    ],
    stateMutability: "payable",
    type: "constructor",
  },
  { inputs: [], name: "Reentrancy", type: "error" },
  {
    inputs: [],
    name: "TokenLocker_AmountNotApprovedForTransferFrom",
    type: "error",
  },
  { inputs: [], name: "TokenLocker_BalanceExceedsTotalAmount", type: "error" },
  {
    inputs: [],
    name: "TokenLocker_DepositGreaterThanTotalAmount",
    type: "error",
  },
  { inputs: [], name: "TokenLocker_IsExpired", type: "error" },
  { inputs: [], name: "TokenLocker_MustDepositTotalAmount", type: "error" },
  { inputs: [], name: "TokenLocker_NonERC20Contract", type: "error" },
  { inputs: [], name: "TokenLocker_NotBuyer", type: "error" },
  { inputs: [], name: "TokenLocker_NotReadyToExecute", type: "error" },
  { inputs: [], name: "TokenLocker_NotSeller", type: "error" },
  { inputs: [], name: "TokenLocker_OnlyOpenOffer", type: "error" },
  { inputs: [], name: "TokenLocker_ValueConditionConflict", type: "error" },
  { inputs: [], name: "TokenLocker_ValueOlderThanOneDay", type: "error" },
  { inputs: [], name: "TokenLocker_ZeroAmount", type: "error" },
  { inputs: [], name: "TransferFailed", type: "error" },
  { inputs: [], name: "TransferFromFailed", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenAmount",
        type: "uint256",
      },
    ],
    name: "TokenLocker_AmountReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "TokenLocker_BuyerReady",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newBuyer",
        type: "address",
      },
    ],
    name: "TokenLocker_BuyerUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bool",
        name: "refundable",
        type: "bool",
      },
      { indexed: false, internalType: "bool", name: "openOffer", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "deposit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expirationTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenContract",
        type: "address",
      },
    ],
    name: "TokenLocker_Deployed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "dataFeedProxy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "enum TokenLocker.ValueCondition",
        name: "valueCondition",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "minimumValue",
        type: "int224",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "maximumValue",
        type: "int224",
      },
    ],
    name: "TokenLocker_DeployedCondition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "depositor",
        type: "address",
      },
    ],
    name: "TokenLocker_DepositInEscrow",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "TokenLocker_DepositedAmountTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "effectiveTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "valueCondition",
        type: "int224",
      },
      {
        indexed: false,
        internalType: "address",
        name: "dataFeedProxy",
        type: "address",
      },
    ],
    name: "TokenLocker_Executed",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "TokenLocker_Expired", type: "event" },
  {
    anonymous: false,
    inputs: [],
    name: "TokenLocker_SellerReady",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newSeller",
        type: "address",
      },
    ],
    name: "TokenLocker_SellerUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "TokenLocker_TotalAmountInEscrow",
    type: "event",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "amountDeposited",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "amountWithdrawable",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyer",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyerApproved",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "checkIfExpired",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "deposit",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "depositTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_depositor", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "depositTokensWithPermit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "deposited",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "execute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "expirationTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_tokenAmount", type: "uint256" },
    ],
    name: "getReceipt",
    outputs: [
      { internalType: "uint256", name: "_paymentId", type: "uint256" },
      { internalType: "uint256", name: "_usdValue", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isExpired",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maximumValue",
    outputs: [{ internalType: "int224", name: "", type: "int224" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minimumValue",
    outputs: [{ internalType: "int224", name: "", type: "int224" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "openOffer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingWithdraw",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "readyToExecute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "refundable",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_depositor", type: "address" }],
    name: "rejectDepositor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "seller",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sellerApproved",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenContract",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAmount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_buyer", type: "address" }],
    name: "updateBuyer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_seller", type: "address" }],
    name: "updateSeller",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "valueCondition",
    outputs: [
      {
        internalType: "enum TokenLocker.ValueCondition",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const EthLockerABI = [
  {
    inputs: [
      { internalType: "bool", name: "_refundable", type: "bool" },
      { internalType: "bool", name: "_openOffer", type: "bool" },
      { internalType: "uint8", name: "_valueCondition", type: "uint8" },
      { internalType: "int224", name: "_maximumValue", type: "int224" },
      { internalType: "int224", name: "_minimumValue", type: "int224" },
      { internalType: "uint256", name: "_deposit", type: "uint256" },
      { internalType: "uint256", name: "_totalAmount", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address payable", name: "_seller", type: "address" },
      { internalType: "address payable", name: "_buyer", type: "address" },
      {
        internalType: "address",
        name: "_dataFeedProxyAddress",
        type: "address",
      },
    ],
    stateMutability: "payable",
    type: "constructor",
  },
  { inputs: [], name: "ETHTransferFailed", type: "error" },
  { inputs: [], name: "EthLocker_BalanceExceedsTotalAmount", type: "error" },
  {
    inputs: [],
    name: "EthLocker_DepositGreaterThanTotalAmount",
    type: "error",
  },
  { inputs: [], name: "EthLocker_IsExpired", type: "error" },
  { inputs: [], name: "EthLocker_MustDepositTotalAmount", type: "error" },
  { inputs: [], name: "EthLocker_NotBuyer", type: "error" },
  { inputs: [], name: "EthLocker_NotReadyToExecute", type: "error" },
  { inputs: [], name: "EthLocker_NotSeller", type: "error" },
  { inputs: [], name: "EthLocker_OnlyOpenOffer", type: "error" },
  { inputs: [], name: "EthLocker_ValueConditionConflict", type: "error" },
  { inputs: [], name: "EthLocker_ValueOlderThanOneDay", type: "error" },
  { inputs: [], name: "EthLocker_ZeroAmount", type: "error" },
  { inputs: [], name: "Reentrancy", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "weiAmount",
        type: "uint256",
      },
    ],
    name: "EthLocker_AmountReceived",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "EthLocker_BuyerReady", type: "event" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newBuyer",
        type: "address",
      },
    ],
    name: "EthLocker_BuyerUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bool",
        name: "refundable",
        type: "bool",
      },
      { indexed: false, internalType: "bool", name: "openOffer", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "deposit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expirationTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
    ],
    name: "EthLocker_Deployed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "dataFeedProxy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "enum EthLocker.ValueCondition",
        name: "valueCondition",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "minimumValue",
        type: "int224",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "maximumValue",
        type: "int224",
      },
    ],
    name: "EthLocker_DeployedCondition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "depositor",
        type: "address",
      },
    ],
    name: "EthLocker_DepositInEscrow",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "EthLocker_DepositedAmountTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "effectiveTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int224",
        name: "valueCondition",
        type: "int224",
      },
      {
        indexed: false,
        internalType: "address",
        name: "dataFeedProxy",
        type: "address",
      },
    ],
    name: "EthLocker_Executed",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "EthLocker_Expired", type: "event" },
  {
    anonymous: false,
    inputs: [],
    name: "EthLocker_SellerReady",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newSeller",
        type: "address",
      },
    ],
    name: "EthLocker_SellerUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "EthLocker_TotalAmountInEscrow",
    type: "event",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "amountDeposited",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "amountWithdrawable",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyer",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyerApproved",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "checkIfExpired",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "dataFeedProxy",
    outputs: [{ internalType: "contract IProxy", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "deposit",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "deposited",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "execute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "expirationTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_weiAmount", type: "uint256" }],
    name: "getReceipt",
    outputs: [
      { internalType: "uint256", name: "_paymentId", type: "uint256" },
      { internalType: "uint256", name: "_usdValue", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isExpired",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maximumValue",
    outputs: [{ internalType: "int224", name: "", type: "int224" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minimumValue",
    outputs: [{ internalType: "int224", name: "", type: "int224" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "openOffer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingWithdraw",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "readyToExecute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "refundable",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "_depositor", type: "address" },
    ],
    name: "rejectDepositor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "seller",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sellerApproved",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAmount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "_buyer", type: "address" },
    ],
    name: "updateBuyer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "_seller", type: "address" },
    ],
    name: "updateSeller",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "valueCondition",
    outputs: [
      {
        internalType: "enum EthLocker.ValueCondition",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export const ReceiptABI = [
  {
    inputs: [],
    stateMutability: "payable",
    type: "constructor"
  },
  {
    inputs: [],
    name: "Receipt_ImproperAmount",
    type: "error"
  },
  {
    inputs: [],
    name: "Receipt_OnlyAdmin",
    type: "error"
  },
  {
    inputs: [],
    name: "Receipt_StalePrice",
    type: "error"
  },
  {
    inputs: [],
    name: "Receipt_TokenNotSupported",
    type
      : "error"
  },
  {
    anonymous: false,
    inputs: [{ "indexed": false, internalType: "address", name: "newAdmin", type: "address" }],
    name: "Receipt_AdminUpdated",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ "indexed": false, internalType: "address", name: "token", type: "address" }, { "indexed": false, internalType: "address", name: "proxy", type: "address" }],
    name: "Receipt_ProxyUpdated",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ "indexed": true, internalType: "uint256", name: "paymentId", type: "uint256" }, { "indexed": false, internalType: "uint256", name: "usdPrice", type: "uint256" }, { indexed: false, internalType: "address", name: "token", type: "address" }],
    name: "Receipt_ReceiptPrinted",
    type: "event"
  },
  {
    inputs: [],
    name: "acceptAdminRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "admin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "paymentIdToUsdValue",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view", type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }, { internalType: "uint256", name: "_tokenAmount", type: "uint256" }, { internalType: "uint256", name: "_decimals", type: "uint256" }],
    name: "printReceipt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable", type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "tokenToProxy",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "_newAdmin", type: "address" }],
    name: "updateAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }, { internalType: "address", name: "_proxy", type: "address" }],
    name: "updateProxy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
]
