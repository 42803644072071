import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useMainContext } from "../../context/contextProvider";
import { GoCopy } from "react-icons/go";
import toast from "react-hot-toast";

export default function TransactionModal() {
  const { modalStatus, setModalStatus, formData } = useMainContext();

  const closeModal = () => {
    setModalStatus({ ...modalStatus, transactionModal: false });
  };

  console.log("Transaction Modal", formData);

  return (
    <Transition appear show={modalStatus.transactionModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Transaction Details
                </Dialog.Title>
                <div className="my-4">
                  <p className="text-xs text-black">
                    <strong>ChainLocker Address: </strong>
                    <span className="text-black/90">
                      {formData?.transaction.logs &&
                        formData?.transaction?.logs[0]?.address
                          ?.slice(0, 15)
                          .concat(
                            "..." +
                            formData?.transaction?.logs[0]?.address?.slice(-7)
                          )}

                      <GoCopy
                        onClick={() => {
                          navigator.clipboard.writeText(
                            formData?.transaction.logs &&
                            formData?.transaction?.logs[0]?.address
                          );
                          toast.success("Copied!");
                        }}
                        className="inline ml-2"
                      />
                    </span>
                  </p>
                  <p className="text-xs text-black">
                    <strong>Transaction Hash: </strong>
                    <span className="text-black/90">
                      {formData?.transaction?.transactionHash &&
                        formData?.transaction?.transactionHash
                          ?.slice(0, 15)
                          .concat(
                            "..." +
                            formData?.transaction?.transactionHash?.slice(-7)
                          )}
                      <GoCopy
                        onClick={() => {
                          navigator.clipboard.writeText(
                            formData?.transaction?.transactionHash &&
                            formData?.transaction?.transactionHash
                          );
                          toast.success("Copied!");
                        }}
                        className="inline ml-2"
                      />
                    </span>
                  </p>
                </div>

                <button
                  onClick={closeModal}
                  className="h-[38px] w-full mt-auto bg-primary text-white px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
                >
                  {/* View Transaction */}
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
