function timeFormator(timestamp) {
  const days = Math.floor(timestamp / (24 * 60 * 60));
  const hours = Math.floor((timestamp % (24 * 60 * 60)) / 3600);
  const minutes = Math.floor((timestamp % 3600) / 60);
  const seconds = timestamp % 60;

  return `${days}D : ${hours.toString().padStart(2, "0")}H : ${minutes
    .toString()
    .padStart(2, "0")}M : ${seconds.toString().padStart(2, "0")}S`;
}

export function startTimer(timestamp) {
  const timerElement = document.getElementById("timer");

  function updateTimer() {
    const currentTime = Math.max(timestamp - Math.floor(Date.now() / 1000), 0);
    const formattedTime = timeFormator(currentTime);
    timerElement.textContent = formattedTime;

    if (currentTime <= 0) {
      clearInterval(interval);
      timerElement.textContent = "Timer Expired";
    }
  }

  updateTimer();
  const interval = setInterval(updateTimer, 1000);
}

export function dateToTimestamp(date) {
  const inputDate = new Date(date);
  // Get the Unix timestamp by dividing the milliseconds by 1000 and rounding it to the nearest whole number
  return Math.round(inputDate.getTime() / 1000);
}

export function timestampToDate(timestamp) {
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

  // Get the various date components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based
  const day = date.getDate().toString().padStart(2, "0");

  // Create a formatted date string in "YYYY-MM-DD" format
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
