import React from "react";
import UpdateBuyerModal from "./modals/UpdateBuyer";
import UpdateSellerModal from "./modals/UpdateSeller";
import DepositAmountModal from "./modals/DepositAmount";
import PrintReceiptModal from "./modals/PrintReceipt";
import RejectDepositorModal from "./modals/RejectDepositor";
import TransactionModal from "./modals/Transaction";
import ReceiptResultModal from "./modals/ReceiptResult"

function Modals() {
  return (
    <React.Fragment>
      <UpdateBuyerModal />
      <UpdateSellerModal />
      <DepositAmountModal />
      <PrintReceiptModal />
      <RejectDepositorModal />
      <TransactionModal />
      <ReceiptResultModal />
    </React.Fragment>
  );
}

export default Modals;
