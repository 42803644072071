import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useMainContext } from "../../context/contextProvider";
import { GoCopy } from "react-icons/go";
import toast from "react-hot-toast";
import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { TokenABI } from "../../contracts/abis";

export default function ReceiptResultModal() {
  const { modalStatus, setModalStatus, selectedChainLocker, formData } = useMainContext();

  const closeModal = () => {
    setModalStatus({ ...modalStatus, receiptResultModal: false });
  };

  const formatAmount = (amount) => {
    if (!amount || isNaN(amount)) {
      return "0";
    }
    let formattedString;
    if (selectedChainLocker?.tokenContract === undefined || selectedChainLocker?.tokenContract === "0x0000000000000000000000000000000000000000") {
      formattedString = ethers.utils.formatUnits(amount, "18").toString();
    } else {
      formattedString = ethers.utils.formatUnits(amount, decimals).toString();
    }
    const [wholePart, decimalPart] = formattedString.split(".");
    return `${wholePart}.${decimalPart}`
  };

  const { data: decimals } = useContractRead({
    address: selectedChainLocker?.tokenContract,
    abi: TokenABI,
    functionName: "decimals",
  });

  console.log("Transaction Modal", formData);

  return (
    <Transition appear show={modalStatus.receiptResultModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Receipt:
                </Dialog.Title>
                <div className="my-4">
                  <p className="text-s text-black">
                    <strong>Payment ID: </strong>
                    <span className="text-black/90 ml-1">
                      {formData.paymentId}

                      <GoCopy
                        onClick={() => {
                          navigator.clipboard.writeText(formData.paymentId);
                          toast.success("Payment ID Copied!");
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    </span>
                  </p>
                  <p className="text-s text-black">
                    <strong>USD Value: </strong> $
                    <span className="text-black/90 ml-1">
                      {formatAmount(formData.usdValue)}

                      <GoCopy
                        onClick={() => {
                          navigator.clipboard.writeText(formatAmount(formData.usdValue));
                          toast.success("USD Value Copied!");
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    </span>
                  </p>
                  <p className="text-s text-black">
                    <strong>Transaction Hash: </strong>
                    <span className="text-black/90 ml-1 text-xs">
                      {formData.receiptTransaction}
                      <GoCopy
                        onClick={() => {
                          navigator.clipboard.writeText(formData.receiptTransaction);
                          toast.success("Transaction Hash Copied!");
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    </span><br></br><br></br><span className="text-xs">This price receipt is immutably printed on your ChainLocker's network, and can be verified by reviewing the event logs from your Print Receipt transaction or by passing your Payment ID to the 'paymentIdToUsdValue' function in the Receipt smart contract.</span>
                  </p>
                </div>

                <button
                  onClick={closeModal}
                  className="h-[38px] w-full mt-auto bg-primary text-neutral-700 px-3 py-2 text-sm rounded-md col-span-3 md:col-span-1"
                >
                  {/* View Transaction */}
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition >
  );
}
