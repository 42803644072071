import { Switch } from "@headlessui/react";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAccount, useContractRead } from "wagmi";
import { disconnect } from "wagmi/actions";
import { deployChainLocker } from "../utils/useContracts";
import { useFactoryContext } from "../contracts/factoryProvider";
import { useMainContext } from "../context/contextProvider";
import UpdateChainButton from "../components/UpdateChain";
import { dateToTimestamp, timestampToDate } from "../utils/timeFormator";
import { TokenABI } from "../contracts/abis";
import { useEthersSigner } from "../utils/ethersAdapter";

function Create() {
  const navigate = useNavigate();
  const signer = useEthersSigner();
  const { address } = useAccount();
  const { factory, minimumFee } = useFactoryContext();
  console.log("Factory:", factory, "Minimum Fee:", minimumFee);
  const {
    modalStatus,
    setModalStatus,
    formData: contextFormData,
    setFormData: setContextFormData,
  } = useMainContext();
  // Define a state variable to store input field data
  const [formData, setFormData] = useState({
    _refundable: true,
    _openOffer: true,
    _valueCondition: 0,
    _maximumValue: "",
    _minimumValue: "",
    _deposit: "",
    _totalAmount: "",
    _expirationTime: dateToTimestamp(new Date()),
    _seller: "",
    _buyer: "",
    _tokenContract: ethers.constants.AddressZero,
    _dataFeedProxyAddress: ethers.constants.AddressZero,
  });
  const [displayDeposit, setDisplayDeposit] = useState("");
  const [displayTotalAmount, setTotalAmount] = useState("");

  // Event handler to update the state when an input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "_deposit" || name === "_totalAmount") {
      const formattedValue = formatTokenAmount(value, decimals); // Format the value based on decimals
      if (name === "_deposit") {
        setDisplayDeposit(value); // Update display value
        setFormData({ ...formData, [name]: formattedValue });
        console.log("formatted", formattedValue)
      } else if (name === "_totalAmount") {
        setTotalAmount(value); // Update display value
        setFormData({ ...formData, [name]: formattedValue });
        console.log("formatted total", formattedValue)
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    console.log("other", value)
  };

  const formatTokenAmount = (amount, decimals) => {
    if (!amount || isNaN(amount)) {
      return "0";
    }
    return ethers.utils.parseUnits(amount, decimals).toString();
  };

  // Event handler for the form submission
  const handleSubmit = () => {
    console.log("Clicked!");
    // Check if all formData fields are filled
    for (const key in formData) {
      if (formData[key] === "" && (key !== "_tokenContract")) {
        toast.error(`Please enter a ${key}.`);
        return; // Exit the function if any field is empty
      }
    }
    if (formData._seller === formData._buyer) {
      toast.error(`Warning: buyer and seller addresses are the same. This may cause loss of funds in the event of conflicting withdrawable amounts post-expiry if neither the buyer nor seller address is updated before the expiration time.`);
    }
    deployChainLocker(
      factory,
      formData,
      minimumFee,
      modalStatus,
      setModalStatus,
      contextFormData,
      setContextFormData,
      signer
    );
  };

  const { data: decimals } = useContractRead({
    address: formData._tokenContract,
    abi: TokenABI,
    functionName: "decimals",
  });

  const { data: tokenSymbol } = useContractRead({
    address: formData._tokenContract,
    abi: TokenABI,
    functionName: "symbol",
  });
  console.log("Token Symbol:", tokenSymbol);

  useEffect(() => {
    if (!address) navigate("/start");
  }, [address, navigate]);

  return (
    <div className="max-w-6xl mx-auto my-20 px-6">
      <h5 className="text-2xl text-primary font-light mb-7">
        Create <strong className="text-white font-semibold">ChainLocker</strong>
      </h5>
      <p className="text-sm">
        Enter your parameters to deploy a ChainLocker.
        <span className="cursor-pointer hover:text-cyan-400" onClick={() => navigate("/consult")}>  Request a Consult</span> for assistance or review the <span className="cursor-pointer hover:text-cyan-400" onClick={() => navigate("/documentation")}>Documentation</span> for more information.<br /><br />
        <div className="mb-3">Choose a network: <UpdateChainButton /></div><p><span className="text-gray-400 cursor-pointer hover:text-cyan-400" onClick={() => disconnect()}>Disconnect Wallet</span></p>
      </p>
      <div className="w-full grid grid-cols-12 gap-4 text-sm md:text-base mt-8">
        <div className="col-span-12 md:col-span-4">
          <div className="text-sm w-32 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Value Condition
          </div>
          <select
            name="_valueCondition"
            value={formData._valueCondition}
            onChange={handleInputChange}
            className="w-full border border-white bg-black text-white rounded-md outline-none px-4 pt-2 pb-2"
          >
            <option value="0" label="None" />
            <option value="1" label="Less Than or Equal to Maximum Value" />
            <option value="2" label="Greater Than or Equal to Minimum Value" />
            <option value="3" label="Less Than or Equal to Maximum Value AND Greater Than or Equal to Minimum Value" />
          </select>
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-36 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Maximum Value
          </div>
          <input
            type="number"
            name="_maximumValue"
            value={formData._maximumValue}
            onChange={handleInputChange}
            placeholder="Value condition maximum"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-36 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Minimum Value
          </div>
          <input
            type="number"
            name="_minimumValue"
            value={formData._minimumValue}
            onChange={handleInputChange}
            placeholder="Value condition minimum"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Deposit
          </div>
          <input
            type="number"
            name="_deposit"
            value={displayDeposit}
            onChange={handleInputChange}
            placeholder="Deposit amount"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-28 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Total Amount
          </div>
          <input
            type="number"
            name="_totalAmount"
            value={displayTotalAmount}
            onChange={handleInputChange}
            placeholder="Total amount"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-24 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Expiry Date
          </div>
          <input
            type="date"
            name="_expirationTime"
            value={timestampToDate(formData._expirationTime + 86400)}
            onChange={(e) =>
              setFormData({
                ...formData,
                _expirationTime: dateToTimestamp(e.target.value),
              })
            }
            className="w-full border border-white text-white bg-transparent rounded-md outline-none px-3 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Seller
          </div>
          <input
            type="text"
            name="_seller"
            value={formData._seller}
            onChange={handleInputChange}
            placeholder="Address to receive assets from the executed ChainLocker"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Buyer
          </div>
          <input
            type="text"
            name="_buyer"
            value={formData._buyer}
            onChange={handleInputChange}
            placeholder="Address to deposit assets into the ChainLocker"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="text-sm w-80 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Data Feed Proxy Address (if applicable)
          </div>
          <input
            type="text"
            name="_dataFeedProxyAddress"
            value={formData._dataFeedProxyAddress}
            onChange={handleInputChange}
            placeholder="Ignored if no Value Condition"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-4" />
        <div className="col-span-12 md:col-span-6">
          <div className="text-sm w-56 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Token Address (if applicable)
          </div>
          <input
            type="text"
            name="_tokenContract"
            value={formData._tokenContract}
            onChange={handleInputChange}
            placeholder="Default is native gas token"
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Symbol
          </div>
          <input
            disabled
            type="text"
            value={
              formData._tokenContract === "0x0000000000000000000000000000000000000000" || formData._tokenContract ===
                undefined
                ? "ETH"
                : tokenSymbol
            }
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Decimals
          </div>
          <input
            disabled
            type="text"
            value={
              formData._tokenContract ===
                "0x0000000000000000000000000000000000000000" || formData._tokenContract ===
                undefined
                ? "18"
                : decimals
            }
            className="w-full border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2"
          />
        </div>
        <hr className="col-span-12 mt-8 mb-6 border-px border-white/20" />
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-20 -mb-2 ml-2 drop-shadow-md px-2 bg-black">Deposit
          </div>
          <div className="w-full flex justify-between items-center border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2">
            <p className="text-sm">{formData._refundable ? "Refundable to Buyer at Expiry" : "Non-Refundable"}</p>
            <Switch
              checked={formData._refundable}
              onChange={() =>
                setFormData({ ...formData, _refundable: !formData._refundable })
              }
              className={`${formData._refundable ? "bg-cyan-400" : "bg-red-400"}
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${formData._refundable ? "translate-x-6" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
        <div className="col-span-6 md:col-span-4">
          <div className="text-sm w-24 -mb-2 ml-2 drop-shadow-md px-2 bg-black">
            Offer Type
          </div>
          <div className="w-full flex justify-between items-center border border-white bg-transparent rounded-md outline-none px-4 pt-2 pb-2">
            <p className="text-sm">{formData._openOffer ? "Open to Anyone" : "Open to Buyer Only"}</p>
            <Switch
              checked={formData._openOffer}
              onChange={() =>
                setFormData({ ...formData, _openOffer: !formData._openOffer })
              }
              className={`${formData._openOffer ? "bg-cyan-400" : "bg-red-400"}
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${formData._openOffer ? "translate-x-6" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
        <div className="col-span-6 md:col-span-4">
          <button
            onClick={handleSubmit}
            className="bg-neutral-900 border border-primary text-white hover:bg-primary w-full mt-3 rounded-md h-[calc(100%-0.75rem)]"
          >
            Deploy ChainLocker
          </button>
        </div>
      </div>
      <p className="text-sm text-neutral-300 justify-center"><br /><br />CAUTION: once deployed, your ChainLocker is immutable. Ensure your inputs are correct and compatible for your purposes.</p>
    </div>
  );
}

export default Create;
