import React from "react";
import Router from "./routes";
import { Toaster } from "react-hot-toast";
// import Dashboard from "./pages/Dashboard";


export default function App() {
  return (
    <React.Fragment>
      <Toaster />
      <Router />
    </React.Fragment>
  );
}
