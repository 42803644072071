import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Rainbow KIT
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, mainnet, WagmiConfig } from "wagmi";
import {
  goerli,
  polygonZkEvm,
  polygon,
  optimism,
  arbitrum,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
// import { alchemyProvider } from "wagmi/providers/alchemy";
// import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import {
  connectorsForWallets,
  getDefaultWallets,
} from "@rainbow-me/rainbowkit";
import { HashRouter } from "react-router-dom";
import { FactoryProvider } from "./contracts/factoryProvider";
import { EventProvider } from "./contracts/eventsProvider";
import { FunctionsProvider } from "./context/contextProvider";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, goerli, polygon, polygonZkEvm, optimism, arbitrum],
  [publicProvider()],
);

const projectId = "65a62e24286f03e12738e37bbca5af84";

const { wallets } = getDefaultWallets({
  appName: "ChainLocker",
  projectId,
  chains,
});

const connectors = connectorsForWallets([...wallets]);

const wagmiClient = createConfig({
  autoConnect: true,
  connectors: connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiClient}>
    <RainbowKitProvider chains={chains}>
      <FactoryProvider>
        <EventProvider>
          <FunctionsProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </FunctionsProvider>
        </EventProvider>
      </FactoryProvider>
    </RainbowKitProvider>
  </WagmiConfig>
);
