import React from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <div className="max-w-6xl text-center mx-auto px-6">

      <h5 className="text-primary font-light text-4xl mt-20">
        <span className="text-white font-semibold">ChainLocker:</span>{" "}
        Automated Escrow
      </h5>
      <p className="mt-3 mx-3 text-xl font-light">
        Non-custodial, user-created, contingent asset release.
      </p>
      <div class="mx-20 mt-10 border border-neutral-300 rounded-lg">
        <p className="mt-4 mx-3 my-3 font-light text-neutral-300">Customize your ChainLocker to be partially refundable, subject to external data, and more.</p>
        <button
          onClick={() => navigate("/create")}
          className="bg-neutral-900 border border-primary text-white hover:bg-primary flex items-center justify-center px-7 py-1 rounded-md mx-auto mt-4 -mt-4 -mb-4"
        >
          Create a ChainLocker
        </button>
      </div>
      <h5 className="text-2xl text-primary font-light text-center mt-40">
        Why use a <strong className="text-white font-semibold">ChainLocker?</strong>
      </h5>
      <p className="mt-3 text-start font-light"><p className="text-cyan-400 text-xl">No Intermediaries</p>Replace traditional escrow accounts and their risks—like human error and banking dependencies—with ownerless smart contracts. Each user-created ChainLocker is unique, ensuring escrowed assets aren't commingled.</p>
      <p className="mt-3 text-start font-light"><p className="text-cyan-400 text-xl"><br />24/7 Transactions</p>Smart contracts avoid costly delays caused by the local working hours of traditional escrow agents and arbitrary requirements of banks.</p>
      <p className="mt-3 text-start font-light"><p className="text-cyan-400 text-xl"><br />Customizable Conditions</p>Automate the release of locked assets based upon objective conditions, tailored to the specific requirements of each transaction.</p>
      <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Refundable Deposit</p><p className="px-20 text-neutral-300">Choose whether any amount of deposited assets is refundable following expiry.</p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Expiry Time</p><p className="px-20 text-neutral-300">Set a deadline. If not executed before expiry, the chosen refundability rules apply.</p></p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Counterparty</p><p className="px-20 text-neutral-300">Identify a counterparty address or create an open offer, permitting any counterparty. Parties may update their own address, and the receiving party may reject any depositor.</p></p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">"Value Condition" Contingency</p><p className="px-20 text-neutral-300">Design a ChainLocker to only execute if an objective data condition, such as a minimum or maximum exchange rate or price of an asset, or other exact value, is satisfied.</p></p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Network</p><p className="px-20 text-neutral-300">Choose the EVM network that meets your security and speed preferences.</p></p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Locked Asset</p><p className="px-20 text-neutral-300">Use native gas tokens (ETH, etc.) or ERC20-compliant tokens, enabling broad contingent payment and tokenization usecases.</p></p>
        <p className="mt-3 text-start font-light"><p className="font-semibold px-10">Receipt</p><p className="px-20 text-neutral-300">Print an immutable US dollar-value “receipt” of the value of certain assets, for recordation and accounting.</p></p></p>
      <p className="mt-3 text-start font-light"><p className="text-cyan-400 text-xl"><br />Reduced Costs</p>Smart contracts minimize transaction costs by removing intermediary custodians and automating calculations, which reduce the high fees associated with escrow services.</p>
      <p className="mt-3 text-start font-light"><p className="text-cyan-400 text-xl"><br />Enhanced Security</p>The release conditions for each ChainLocker are immutable, preventing anyone from changing any parameter, condition, or the transaction record.</p>
    </div>
  );
}

export default Home;
